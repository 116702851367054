import React from "react";
import { connect } from "react-redux";
import { Formik } from "formik";

import { BooleanSelect, SubmitButton, TextInput } from "components/forms";
import { Form } from "react-bootstrap";
import { activeMortalityAssessmentSelector, shouldSaveSelector } from "reducers";
import GenderSelect from "components/forms/GenderSelect";
import { editMortalityAssessment, generateCustomCurve, unsetShouldSave, selectMortalityAssessment } from "actions";

const MortalityAssessmentCurveForm = ({ mortalityAssessmentInfo, ...props }) => {
  if (!mortalityAssessmentInfo) {
    return null;
  }

  return (
    <Formik
      enableReinitialize
      initialValues={{
        ...mortalityAssessmentInfo,
      }}
      onSubmit={async (values, { setSubmitting, setErrors }) => {
        await props.generateCustomCurve(mortalityAssessmentInfo.id, values);
        props.selectMortalityAssessment(mortalityAssessmentInfo?.id);
      }}
    >
      {({ handleSubmit, values, setFieldValue }) => {
        return (
          <Form onSubmit={handleSubmit} className={"form shadowed"} {...props}>
            <Form.Row>
              <TextInput label="Base Table" name="curve_base_table" md={3} />
              <GenderSelect md={2} />
              <BooleanSelect label="Tobacco" name="curve_tobacco_use" />
              <TextInput
                label="Mortality Multiplier"
                name="curve_mortality_multiplier"
                placeholder="Enter %"
                append="%"
              />
              <TextInput
                label="Mortality Improvement"
                name="curve_mortality_improvement"
                placeholder="Enter %"
                append="%"
              />
            </Form.Row>
            <Form.Row>
              <TextInput label="Assessment Age" name="curve_assessment_age" />
              <TextInput label="Mean LE" name="curve_mean_months" disabled />
              <TextInput label="Median LE" name="curve_median_months" disabled />
              <TextInput label="VBT Mean Back Solve" name="vbt_mean" append="%" />
            </Form.Row>
            <SubmitButton defaultText={"Generate"} />
          </Form>
        );
      }}
    </Formik>
  );
};

const mapStateToProps = state => {
  return {
    shouldSave: shouldSaveSelector(state),
    mortalityAssessmentInfo: activeMortalityAssessmentSelector(state),
  };
};

export default connect(mapStateToProps, {
  editMortalityAssessment,
  unsetShouldSave,
  generateCustomCurve,
  selectMortalityAssessment,
})(MortalityAssessmentCurveForm);
