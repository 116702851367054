import React, { useState } from "react";
import { Form } from "react-bootstrap";
import { connect } from "react-redux";
import { Formik } from "formik";
import { CurrencyInput, DateInput, GenderSelect, Select, StateSelect, SubmitButton, TextInput } from "components/forms";
import { rateClassChoices, tableRateChoices } from "../../../constants";
import { makeChoices } from "../../funders/utils";
import { fetchInsured, fetchProducts, fetchWinFlexChoices, runWinFlexIllustration, hideModal } from "actions";
import {
  activeCaseFileSelector,
  activeCaseIdSelector,
  activeCaseSelector,
  carriersSelector,
  insuredListSelector,
  policySelector,
  winflexChoicesSelector,
} from "../../../reducers";
import FormCollapsible from "../../../components/forms/FormCollapsible";
import { calculateExactAge, includesAny } from "../../../utils";
import { WINFLEX_ILLUSTRATION_MODAL } from "../../../components/modals";
import * as notifications from "../../../notifications";
import { usStatesChoices } from "../../../options";

const WinFlexForm = props => {
  if (!props.winflexChoices || props.winflexChoices.length === 0) {
    return;
  }

  const initialChoices = makeChoices(props.winflexChoices?.["Lincoln WealthPreserve 2 IUL"]?.rate_classes);
  const [rateClassChoicesForSelectedScript, setRateClassChoicesForSelectedScript] = useState(initialChoices);
  const [nlgPeriod, setNLGPeriod] = useState(25);
  const [nlgPeriodDisabled, setNLGPeriodDisabled] = useState(false);
  const [endYear, setEndYear] = useState(100);
  const [stateChoices, setStateChoices] = useState(usStatesChoices.getChoices());

  const exactAge = calculateExactAge(props.insuredInfo?.date_of_birth);
  let initialValues = {
    insured_name: `${props.insuredInfo?.first_name} ${props.insuredInfo?.last_name}`,
    insured_gender: props.insuredInfo?.gender,
    insured_dob: props.insuredInfo?.date_of_birth,
    insured_age: exactAge.toFixed(1),
    input_age: Math.round(exactAge),
    end_year: 100,
    nlg_length: 25,
    jurisdiction: props.caseInfo.jurisdiction,
    carrier: props.caseInfo.carrier_name,
    product: props.policyInfo.product_name,
    rate_class: props.policyInfo.insured_1_rate_class,
    table_rating: props.policyInfo.insured_1_table_rate,
    selected_state: props.caseInfo.jurisdiction,
    selected_script: "Lincoln WealthPreserve 2 IUL",
    selected_rate_class: "Preferred Plus",
    selected_table_rating: "",
    face_amount: props.policyInfo.face_amount,
    winflex_login: "agiroux@ims4u.net",
  };

  // Disable and clear tableRateChoices if the Rating Class contains any of the following values
  const tableRateDisablingClasses = ["Preferred", "Prfd", "Super", "Plus"];

  return (
    <Formik
      enableReinitialize
      initialValues={initialValues}
      onSubmit={async (values, { setSubmitting, setErrors, setFieldError }) => {
        setSubmitting(true);
        values.case_id = props.activeCaseId;
        values.case_document_id = props.activeCaseFileId;
        try {
          await props.runWinFlexIllustration(values);
          props.hideModal(WINFLEX_ILLUSTRATION_MODAL);
        } catch (error) {
          setErrors(error.response.data);
          notifications.error("Error creating WinFlex Illustration Request");
        }
      }}
    >
      {({ handleSubmit, values, setFieldValue }) => {
        const handleSelectedScriptChange = e => {
          if (!e) {
            setFieldValue("selected_script", undefined);
            setFieldValue("selected_rate_class", undefined);
            setFieldValue("selected_table_rating", undefined);
            return;
          }
          console.log("selected", e.value, props.winflexChoices[e.value]);
          setFieldValue("selected_script", e.value);
          setFieldValue("selected_rate_class", undefined);
          setFieldValue("selected_table_rating", undefined);

          let _rateClassChoices = props.winflexChoices[e.value].rate_classes;
          setRateClassChoicesForSelectedScript(makeChoices(_rateClassChoices));

          let _nlg_length = props.winflexChoices[e.value].nlg_length;
          if (e.value === "Lincoln WealthPreserve 2 IUL") {
            _nlg_length = Math.min(25, 90 - values.input_age);
          }
          // Disable "New York" state for Principal II script
          if (e.value === "Principal IUL Flex II") {
            if (values.selected_state === "NY") {
              setFieldValue("selected_state", undefined);
            }
            setStateChoices(usStatesChoices.getChoices().filter(option => option.value !== "NY"));
          } else {
            setStateChoices(usStatesChoices.getChoices());
          }
          if (_nlg_length) {
            setNLGPeriod(_nlg_length);
            setNLGPeriodDisabled(false);
            setFieldValue("nlg_length", _nlg_length);
          } else {
            setNLGPeriod("");
            setNLGPeriodDisabled(true);
            setFieldValue("nlg_length", _nlg_length);
          }

          let _end_year = props.winflexChoices[e.value].end_year || 105;
          setEndYear(_end_year);
          setFieldValue("end_year", _end_year);
        };

        const handleSelectedRateClassChange = e => {
          if (!e) {
            setFieldValue("selected_rate_class", undefined);
            setFieldValue("selected_table_rating", undefined);
            return;
          }
          setFieldValue("selected_rate_class", e.value);
          setFieldValue("selected_table_rating", undefined);
        };

        const handleNLGPeriodChange = e => {
          const value = e.target.value;
          setFieldValue("nlg_length", value);
          setNLGPeriod(value);
        };

        const handleEndYearChange = e => {
          const value = e.target.value;
          setFieldValue("end_year", value);
          setEndYear(value);
        };

        return (
          <FormCollapsible onSubmit={handleSubmit} title={"Information Review"}>
            <Form.Row>
              <TextInput label="Insured Name" name="insured_name" disabled />
              <GenderSelect label="Insured Gender" name="insured_gender" disabled />
              <DateInput label="Insured DOB" name="insured_dob" disabled />
              <TextInput label="Insured Age" name="insured_age" disabled />
            </Form.Row>
            <Form.Row>
              <StateSelect label="Jurisdiction" name="jurisdiction" disabled md={2} />
              <TextInput label="Carrier" name="carrier" disabled />
              <TextInput label="Product" name="product" disabled />
              <Select label="Rate Class" name="rate_class" options={rateClassChoices} disabled />
              <Select label="Table Rating" name="table_rating" options={tableRateChoices} disabled />
            </Form.Row>
            <hr />
            <Form.Row>
              <StateSelect name="selected_state" choices={stateChoices} md={2} />
              <Select
                label="Script"
                name="selected_script"
                placeholder="Select Script"
                options={props.scriptChoices}
                onChange={handleSelectedScriptChange}
                md={4}
              />
              <Select
                label="Select Rate Class"
                name="selected_rate_class"
                placeholder="Select Rate Class"
                onChange={handleSelectedRateClassChange}
                options={rateClassChoicesForSelectedScript}
              />
              <Select
                label="Select Table Rating"
                name="selected_table_rating"
                placeholder="Select Table Rating"
                options={tableRateChoices}
                disabled={
                  !values.selected_rate_class || includesAny(values.selected_rate_class, tableRateDisablingClasses)
                }
              />
            </Form.Row>
            <Form.Row>
              <TextInput type="number" label="Input Age" name="input_age" step="1" />
              <TextInput
                type="number"
                label="End Year"
                name="end_year"
                step="1"
                value={endYear}
                onChange={handleEndYearChange}
              />
              <TextInput
                type="number"
                label="NLG Period"
                name="nlg_length"
                step="1"
                value={nlgPeriod}
                disabled={nlgPeriodDisabled}
                onChange={handleNLGPeriodChange}
              />
              <Select
                label="WinFlex Log-In"
                name="winflex_login"
                disabled
                placeholder="Select Log-In"
                options={props.winFlexLoginChoices}
                defaultValue={props.winFlexLoginChoices[0]}
              />
            </Form.Row>
            <Form.Row>
              <CurrencyInput label="Face Amount" name="face_amount" md={3} />
            </Form.Row>
            <SubmitButton
              defaultText="Start Request"
              submittingText="Connecting..."
              style={{
                width: "93%",
                marginTop: "1rem",
                marginBottom: "1rem",
              }}
            />
          </FormCollapsible>
        );
      }}
    </Formik>
  );
};

const mapStateToProps = state => {
  const winflexChoices = winflexChoicesSelector(state);
  const activeCaseFile = activeCaseFileSelector(state);
  const activeCaseFileId = activeCaseFile.id;
  return {
    caseInfo: activeCaseSelector(state),
    activeCaseId: activeCaseIdSelector(state),
    activeCaseFileId,
    carriers: carriersSelector(state),
    policyInfo: policySelector(state),
    insuredInfo: insuredListSelector(state)[0],
    winflexChoices,
    scriptChoices: makeChoices(Object.keys(winflexChoices).sort()),
    winFlexLoginChoices: makeChoices(["agiroux@ims4u.net"]),
  };
};

export default connect(mapStateToProps, {
  fetchProducts,
  fetchWinFlexChoices,
  fetchInsured,
  winflexChoicesSelector,
  runWinFlexIllustration,
  hideModal,
})(WinFlexForm);
