import {
  CLEAN_ACCOUNT_CASES,
  FETCH_ACCOUNT_CASES,
  CLEAR_SELECTED_ACCOUNT,
  SELECT_ACCOUNT,
  SELECT_MANAGE_ENTITY,
  FETCH_ACCOUNTS,
  EDIT_ACCOUNT,
  CREATE_ACCOUNT,
  FETCH_ACCOUNT,
  SELECT_ACCOUNT_TASK,
  SELECT_ACCOUNT_AGREEMENT,
  SELECT_ACCOUNT_LICENSE,
  CREATE_ACCOUNT_LICENSE,
  EDIT_ACCOUNT_LICENSE,
  EDIT_ACCOUNT_AGREEMENT,
  CREATE_ACCOUNT_AGREEMENT,
  EDIT_ACCOUNT_TASK,
  SELECT_ACCOUNT_HIERARCHY,
  EDIT_ACCOUNT_HIERARCHY,
  CREATE_ACCOUNT_HIERARCHY,
  EDIT_ACCOUNT_AFFILIATION,
  CREATE_ACCOUNT_AFFILIATION,
  SELECT_ACCOUNT_AFFILIATION,
  FETCH_ACCOUNT_ENTITY_CHOICES,
  DELETE_ACCOUNT,
  CLEAR_ACCOUNT_HIERARCHY,
  SELECT_SPLIT,
  FETCH_SPLITS,
  CLEAR_SPLITS,
  LOADING,
  SELECT_ACCOUNT_LIST,
  DESELECT_ACCOUNT_LIST,
  FETCH_ENGAGEMENT_ACTIVITIES,
  SELECT_ACCOUNT_CARRIER_APPOINTMENT,
  EDIT_ACCOUNT_CARRIER_APPOINTMENT,
  CREATE_ACCOUNT_CARRIER_APPOINTMENT,
} from "actions/types";
import { arrayToObjectIndexedById } from "../../utils";

export const defaultState = {
  accounts: {},
  cases: {},
  filters: {},
  activeAccountId: null,
  activeHierarchyId: null,
  manageEntityId: null,
  activeAffiliation: null,
  entityChoices: [],
  availableEntitiesForUser: [],
  activeTask: {},
  activeCarrierAppointment: {},
  activeLicense: {},
  activeAgreement: {},
  activeHierarchy: {},
  activeSplit: {},
  splits: {},
  engagementActivities: [],
  selectedAccounts: [],
};

export default (state = defaultState, action) => {
  switch (action.type) {
    case CLEAN_ACCOUNT_CASES:
      return {
        ...state,
        cases: defaultState.cases,
      };
    case FETCH_ACCOUNT_CASES:
      return {
        ...state,
        cases: action.payload,
      };

    case CLEAR_SELECTED_ACCOUNT:
      return { ...state, activeAccountId: defaultState.activeAccountId };

    case SELECT_ACCOUNT:
      return { ...state, activeAccountId: action.payload };

    case SELECT_MANAGE_ENTITY:
      return { ...state, manageEntityId: action.payload };

    case EDIT_ACCOUNT_TASK:
    case SELECT_ACCOUNT_TASK:
      return { ...state, activeTask: action.payload };

    case SELECT_ACCOUNT_CARRIER_APPOINTMENT:
      return { ...state, activeLicense: action.payload };

    case SELECT_ACCOUNT_LICENSE:
      return { ...state, activeLicense: action.payload };

    case SELECT_ACCOUNT_AGREEMENT:
      return { ...state, activeAgreement: action.payload };

    case SELECT_ACCOUNT_HIERARCHY:
      return { ...state, activeHierarchy: action.payload };

    case SELECT_SPLIT:
      return { ...state, activeSplit: action.payload };

    case FETCH_SPLITS:
      return { ...state, splits: action.payload };

    case CLEAR_SPLITS:
      return { ...state, splits: defaultState.splits };

    case CLEAR_ACCOUNT_HIERARCHY:
      return { ...state, activeHierarchy: defaultState.activeHierarchy };

    case SELECT_ACCOUNT_AFFILIATION:
      return { ...state, activeAffiliation: action.payload };

    case SELECT_ACCOUNT_LIST:
      return {
        ...state,
        selectedAccounts: [...state.selectedAccounts, action.payload.id],
      };

    case DESELECT_ACCOUNT_LIST:
      return {
        ...state,
        selectedAccounts: state.selectedAccounts.filter(item => item !== action.payload.id),
      };

    case FETCH_ACCOUNT: {
      return {
        ...state,
        accounts: {
          ...state.accounts,
          [action.payload.id]: {
            ...state.accounts[action.payload.id],
            ...action.payload,
          },
        },
      };
    }

    case FETCH_ACCOUNTS:
      return { ...state, accounts: arrayToObjectIndexedById(action.payload) };

    case EDIT_ACCOUNT:
    case CREATE_ACCOUNT: {
      return {
        ...state,
        activeAccountId: action.payload.id,
        accounts: {
          ...state.accounts,
          [action.payload.id]: action.payload,
        },
      };
    }

    case DELETE_ACCOUNT:
      delete state.accounts[action.payload.id];
      return state;

    case EDIT_ACCOUNT_CARRIER_APPOINTMENT:
    case CREATE_ACCOUNT_CARRIER_APPOINTMENT:
      return { ...state, activeCarrierAppointment: action.payload };

    case EDIT_ACCOUNT_LICENSE:
    case CREATE_ACCOUNT_LICENSE:
      return { ...state, activeLicense: action.payload };

    case EDIT_ACCOUNT_AGREEMENT:
    case CREATE_ACCOUNT_AGREEMENT:
      return { ...state, activeAgreement: action.payload };

    case EDIT_ACCOUNT_HIERARCHY:
    case CREATE_ACCOUNT_HIERARCHY:
      return { ...state, activeHierarchy: action.payload };

    case EDIT_ACCOUNT_AFFILIATION:
    case CREATE_ACCOUNT_AFFILIATION:
      return { ...state, activeAffiliation: action.payload };

    case FETCH_ACCOUNT_ENTITY_CHOICES:
      return {
        ...state,
        entityChoices: action.payload.entities,
        availableEntitiesForUser: action.payload.available_entities_for_user,
      };

    case FETCH_ENGAGEMENT_ACTIVITIES:
      return { ...state, engagementActivities: action.payload };
    case LOADING:
      if (action.payload.accounts) {
        return {
          ...state,
          accounts: arrayToObjectIndexedById([]),
        };
      } else {
        return state;
      }

    default:
      return state;
  }
};

export const activeAccountSelector = ({ accounts, activeAccountId }) => {
  if (activeAccountId) {
    return accounts[activeAccountId];
  }
};

export const manageEntitySelector = ({ accounts, manageEntityId }) => {
  if (manageEntityId) {
    return accounts[manageEntityId];
  }
};

export const activeAccountIdSelector = state => {
  return state.activeAccountId;
};

export const manageEntityIdSelector = state => {
  return state.manageEntityId;
};

export const activeTaskSelector = state => {
  return state.activeTask;
};

export const activeCarrierAppointmentSelector = state => {
  return state.activeCarrierAppointment;
};

export const activeLicenseSelector = state => {
  return state.activeLicense;
};

export const activeAgreementSelector = state => {
  return state.activeAgreement;
};

export const activeHierarchySelector = state => {
  return state.activeHierarchy;
};

export const activeHierarchyIdSelector = state => {
  return state.activeHierarchy.id;
};

export const activeSplitSelector = state => state.activeSplit;

export const activeSplitIdSelector = state => state.activeSplit && state.activeSplit.id;

export const activeAffiliationSelector = state => {
  return state.activeAffiliation;
};

export const accountsSelector = state => state.accounts;

export const accountsListSelector = state => {
  return Object.values(state.accounts);
};

export const accountCasesSelector = state => Object.values(state.cases);

export const accountEntityChoicesSelector = state => state.entityChoices;

export const availableEntitiesSelector = state => state.availableEntitiesForUser;

export const engagementActivitiesSelector = state => state.engagementActivities;

export const selectedAccountsSelector = state => state.selectedAccounts;
