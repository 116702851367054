import React from "react";
import { connect } from "react-redux";

import { predictionsSelector, loadingPredictionsSelector } from "reducers";
import Table from "components/table";

const LifeExpectancyPredictorTable = ({ ...props }) => {
  const columns = [
    {
      Header: "LE Age",
      accessor: "le_age",
    },
    {
      Header: "Mortality Multiplier",
      accessor: "mm",
    },
    {
      Header: "Prediction",
      accessor: "prediction",
    },
    {
      Header: "Vendor",
      accessor: "vendor",
    },
  ];

  return (
    <Table
      columns={columns}
      data={props.data}
      emptyMessage={props.loading ? "Loading data..." : "No data"}
      initialPageSize={100}
    />
  );
};

const mapStateToProps = state => {
  let predictions = predictionsSelector(state);

  return {
    data: predictions,
    loading: loadingPredictionsSelector(state),
  };
};

export default connect(mapStateToProps, {})(LifeExpectancyPredictorTable);
