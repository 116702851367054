export const makeChoices = list => {
  let choices = [];
  for (const listElement of list) {
    choices.push({ label: listElement, value: listElement });
  }
  return choices;
};

export function prepareMultipleSelect(fieldName, choices, funderInfo) {
  let result = [];
  if (funderInfo[fieldName]) {
    let values = funderInfo[fieldName].split(";");
    for (let val of values) {
      let data = choices.find(e => e.value.toString() === val.toString());
      if (data) {
        result.push({ value: data.value, label: data.label });
      }
    }
  }

  // If the initialValue has no comma, it locks for changes.
  // Blank it so the defaultValue takes preference
  if (",".indexOf(funderInfo[fieldName]) === -1) {
    funderInfo[fieldName] = "";
  }

  return result;
}

export const leVendorChoices = makeChoices([
  "Internal",
  "21st Services",
  "AVS Underwriting",
  "Clarity",
  "Fasano Associates",
  "ISC",
  "Longevity Services",
  "Predictive Resources",
  "Lapetus Solution",
  "Focus",
  "Convergence",
  "Polaris",
]);

export const ndaInPlaceChoices = [
  { value: 1, label: "Yes" },
  { value: 0, label: "No" },
  { value: 2, label: "Pending" },
];

export const underwritingMethodChoices = [
  { value: 1, label: "Primarily Internal" },
  { value: 2, label: "Secondary Internal" },
  { value: 3, label: "No Internal" },
];

export const predictorVendorChoices = [
  { value: "FASANO", label: "Fasano" },
  { value: "LSI", label: "LSI" },
];

// These are taken as a snapshot from the database
export const servicerChoicesLazy = [
  { label: "LifeRoc Services, LLC", value: 1 },
  { label: "Asset Servicing Group, LLC", value: 2 },
  { label: "Montgomery Servicing", value: 3 },
  { label: "ITM Twentyfirst, LLC", value: 4 },
  { label: "Lyric Services, LLC", value: 5 },
  { label: "Magna Servicing", value: 6 },
  { label: "NorthStar Life Services", value: 7 },
];
