import React from "react";
import { connect } from "react-redux";
import { Formik } from "formik";

import {
  BooleanSelect,
  Collapsible,
  CurrencyInput,
  DateInput,
  DateTimeInput,
  EditorField,
  GenderSelect,
  HyperLinkText,
  Select,
  StateSelect,
  SubmitButton,
  TextInput,
} from "components/forms";
import { activeLeadSelector, managementChoicesSelector, shouldSaveSelector } from "reducers";
import { Form } from "react-bootstrap";
import {
  editLead,
  unsetShouldSave,
  setShouldSave,
  showModal,
  selectLeadTask,
  fetchLifeSettlementChoices,
  runAllocation,
  fetchLeadTasks,
  fetchLeadEngagementActivities,
} from "actions";
import { isAdmin } from "permissions";

import NumericInput from "../../components/forms/NumericInput";
import {
  LEAD_HEALTH_STATUS_OPTIONS,
  LEAD_ROLES_CHOICES,
  MARKETING_ACTIONS,
  MARKETING_CAMPAIGN_SOURCES,
  MARKETING_PROPERTIES,
} from "./constants";
import { leadStatusChoices, productTypeChoices } from "../../constants";
import * as notifications from "../../notifications";
import { currencyCellMoneyDisplay, partial } from "../../utils";
import { RecordsTable } from "../../components/table";
import {
  LEAD_TASK_MODAL,
  SELECT_ACCOUNT_FOR_ADVISOR_LEAD_MODAL,
  SEND_SALES_REP_LEAD_EMAIL_MODAL,
} from "../../components/modals";
import LeadTaskTable from "./tasks/LeadTaskTable";
import { ButtonCreate } from "../../components/buttons";
import EngagementActivitiesTable from "../accounts/information/engagementActivities/EngagementActivitiesTable";

const LeadForm = ({ leadInfo, fetchLifeSettlementChoices, fetchLeadEngagementActivities, ...props }) => {
  if (!leadInfo) {
    // Still didn't fetch data
    return null;
  }

  const submitRef = React.useRef();

  React.useEffect(() => {
    fetchLifeSettlementChoices();
    fetchLeadEngagementActivities();
  }, [fetchLifeSettlementChoices]);

  React.useEffect(() => {
    if (props.shouldSave) {
      submitRef.current.click();
    }
  }, [props.shouldSave]);

  let formattedFaceAmount;
  if (leadInfo.face_amount) {
    formattedFaceAmount = currencyCellMoneyDisplay(leadInfo.face_amount);
    if (formattedFaceAmount) {
      formattedFaceAmount = formattedFaceAmount.replace("$ ", "");
    }
  }

  return (
    <div className={`section-card shadowed case-section`} style={{ marginTop: "3.5rem" }}>
      <Formik
        enableReinitialize
        initialValues={{
          ...leadInfo,
          face_amount: formattedFaceAmount,
        }}
        onSubmit={async (values, { setSubmitting, setErrors }) => {
          setSubmitting(false);
          const method = partial(props.editLead, leadInfo.id);
          delete values.related_account_name;
          try {
            await method(values);
            notifications.success("Lead saved successfully");

            // When assigning a Lead from Empty to a new Sales Rep, show Email Notification Popup
            if (values.sales_representative_id && !leadInfo.sales_representative_id) {
              // Notify the Sales Rep, National Account Manager and Sales Manager
              notifications.warn("Notify Sales Rep of new Lead assigned");
              props.showModal(SEND_SALES_REP_LEAD_EMAIL_MODAL, {
                sales_representative_id: values.sales_representative_id,
              });
            }
          } catch (error) {
            setErrors(error.response.data);
            notifications.error("Error editing Lead");
          }
          props.unsetShouldSave();
          setSubmitting(false);
        }}
      >
        {({ handleSubmit, values, setFieldValue }) => {
          return (
            <Form onSubmit={handleSubmit} className={"form shadowed"} {...props}>
              <Collapsible title="Detail">
                <Form.Row>
                  <TextInput label="Lead ID" name="system_id" disabled />
                  <Select
                    label="Sales Rep"
                    name="sales_representative_id"
                    placeholder="Select Sales Rep"
                    disabled={!props.hasAdminPermission}
                    options={props.salesRepChoices.getChoices({ sortByLabel: true })}
                    emptyValue={null}
                  />
                  <Select label="Lead Status" name="status" placeholder="Select Status" options={leadStatusChoices} />
                  {props.hasAdminPermission && (
                    <ButtonCreate
                      onClick={async e => {
                        e.preventDefault();
                        try {
                          await props.editLead(leadInfo.id, values);
                          await props.runAllocation(leadInfo.id);
                          await props.fetchLeadTasks(leadInfo.id);
                        } catch (e) {
                          notifications.error("Error in Run Allocation");
                        }
                      }}
                      key="create"
                      className="btn--pricing-setup"
                      style={{
                        height: "22px",
                        position: "absolute",
                        right: "calc(34%)",
                        padding: "0 5px",
                        marginTop: 1,
                        fontSize: 14,
                      }}
                    >
                      Run Allocation
                    </ButtonCreate>
                  )}
                </Form.Row>
                <Form.Row>
                  <TextInput label="First Name" name="first_name" />
                  <TextInput label="Last Name" name="last_name" />
                  <TextInput label="Nick Name" name="nick_name" />
                </Form.Row>
                <Form.Row>
                  <Select label="Lead Role" name="role" placeholder="Select Role" options={LEAD_ROLES_CHOICES} />
                  {values.role === 6 && values.account_id && (
                    <HyperLinkText
                      label="Related Account"
                      name="related_account_name"
                      url={`/accounts/${values.account_id}/information/`}
                      inline={false}
                      disabled
                    />
                  )}
                  {values.role === 6 && !values.account_id && (
                    <TextInput label="Related Account" name="related_account_name" disabled />
                  )}
                  {props.hasAdminPermission && values.role === 6 && !values.account_id && (
                    <ButtonCreate
                      onClick={async e => {
                        e.preventDefault();
                        props.showModal(SELECT_ACCOUNT_FOR_ADVISOR_LEAD_MODAL);
                        await props.setShouldSave();
                      }}
                      key="create"
                      className="btn--pricing-setup"
                      style={{
                        height: "22px",
                        position: "absolute",
                        right: "calc(50.3%)",
                        padding: "0 5px",
                        marginTop: 1,
                        fontSize: 14,
                      }}
                    >
                      Select Account
                    </ButtonCreate>
                  )}
                  <TextInput label="Phone Number" name="phone" />
                  <TextInput label="Email" lowercase={true} name="email" />
                </Form.Row>
                <Form.Row>
                  <TextInput label="Fax Number" name="fax" />
                  <TextInput label="Other Phone" name="other_phone" />
                  <TextInput label="Other Email" name="other_email" lowercase={true} />
                </Form.Row>
                <Form.Row>
                  <TextInput label="Street Address" name="street_address" md={12} />
                </Form.Row>
                <Form.Row>
                  <TextInput label="City" name="city" />
                  <StateSelect label="State" name="state" placeholder="Select State" />
                  <TextInput label="Zip Code" name="zip_code" />
                </Form.Row>
                <Form.Row>
                  <BooleanSelect label="Email Opt Out" name="email_opt_out" md={4} />
                </Form.Row>
                <Form.Row>
                  <EditorField label="Notes" name="notes" />
                </Form.Row>
                <SubmitButton innerRef={submitRef} style={{ display: "none" }} />
              </Collapsible>
              <Collapsible title="Lead Detail">
                <Form.Row>
                  <TextInput label="Lead Carrier" name="carrier_name" />
                  <CurrencyInput label="Face Amount" name="face_amount" />
                  <Select
                    label="Product Type"
                    name="policy_type"
                    placeholder="Select Product"
                    options={productTypeChoices}
                  />
                  <StateSelect label="Owner State" name="state" placeholder="Select State" md={3} />
                </Form.Row>
                <Form.Row>
                  <Select
                    label="Carrier"
                    name="carrier_id"
                    placeholder="Select Carrier"
                    options={props.carrierChoices.getChoices()}
                    md={3}
                    emptyValue={null}
                  />
                  <TextInput label="Policy Number" name="policy_number" md={3} />
                  <DateInput label="Policy Effective Date" name="policy_effective_date" md={3} />
                </Form.Row>
                <Form.Row>
                  <Select
                    label="Insured Lives"
                    name="number_of_insured_lives"
                    placeholder="Select Insured Lives"
                    emptyValue={null}
                    options={[
                      { value: 1, label: "Single" },
                      { value: 2, label: "Joint Survivor" },
                    ]}
                    md={3}
                  />
                  <TextInput label="Insured 1 Age:" name="insured_1_age" type="number" md={3} />
                  <TextInput label="Insured 2 Age:" name="insured_2_age" type="number" md={3} />
                </Form.Row>
                <Form.Row>
                  <DateInput label="Insured 1 DOB" name="insured_1_dob" />
                  <GenderSelect label="Insured 1 Gender:" name="insured_1_gender" />
                  <Select
                    label="Insured 1 Health:"
                    name="insured_1_health"
                    placeholder="Select Health Status"
                    options={LEAD_HEALTH_STATUS_OPTIONS}
                  />
                  <BooleanSelect label="Insured 1 Tobacco" name="insured_1_tobacco_use" />
                </Form.Row>
                <Form.Row>
                  <DateInput label="Insured 2 DOB" name="insured_2_dob" />
                  <GenderSelect label="Insured 2 Gender:" name="insured_2_gender" />
                  <Select
                    label="Insured 2 Health:"
                    name="insured_2_health"
                    placeholder="Select Health Status"
                    options={LEAD_HEALTH_STATUS_OPTIONS}
                  />
                  <BooleanSelect label="Insured 2 Tobacco" name="insured_2_tobacco_use" />
                </Form.Row>
              </Collapsible>
              <Collapsible title="Qualitative Insight">
                <Form.Row>
                  <Select
                    label="Marketing Campaign"
                    name="marketing_campaign"
                    placeholder="Select Marketing Campaign"
                    options={MARKETING_CAMPAIGN_SOURCES}
                  />
                  <Select
                    label="Marketing Property"
                    name="marketing_property"
                    placeholder="Select Marketing Property"
                    options={MARKETING_PROPERTIES}
                  />
                  <Select
                    label="Marketing Action"
                    name="marketing_action"
                    placeholder="Select Marketing Action"
                    options={MARKETING_ACTIONS}
                  />
                </Form.Row>
                <Form.Row>
                  <NumericInput label="Lead Score" name="score" disabled md={2} />
                  <DateInput label="Date of First Engagement" name="date_created" disabled />
                  <DateInput label="Date Lead Created" name="date_created" disabled />
                </Form.Row>
                <Form.Row>
                  <TextInput
                    label="Last Marketing Engagement"
                    name="last_marketing_engagement"
                    placeholder="TBD"
                    disabled
                  />
                  <DateTimeInput label="Date Lead Last Modified" name="last_edit_date" disabled />
                  <TextInput label="Last Modified By" name="last_edited_by_name" disabled />
                </Form.Row>
              </Collapsible>
            </Form>
          );
        }}
      </Formik>
      <RecordsTable
        title="Tasks Detail"
        createButtonLabel="Add Task"
        onCreateClick={() => {
          props.selectLeadTask(null);
          props.showModal(LEAD_TASK_MODAL);
        }}
        Table={LeadTaskTable}
      />
      <RecordsTable title="Engagement Activity" actionButtons={[]} Table={EngagementActivitiesTable} />
    </div>
  );
};

const mapStateToProps = state => {
  const choices = managementChoicesSelector(state);

  return {
    shouldSave: shouldSaveSelector(state),
    carrierChoices: choices.carriers,
    hasAdminPermission: isAdmin(state),
    leadInfo: activeLeadSelector(state),
    salesRepChoices: choices.case_sales_representatives,
  };
};

export default connect(mapStateToProps, {
  editLead,
  setShouldSave,
  unsetShouldSave,
  showModal,
  selectLeadTask,
  fetchLifeSettlementChoices,
  fetchLeadEngagementActivities,
  runAllocation,
  fetchLeadTasks,
})(LeadForm);
