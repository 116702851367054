import {
  SELECT_MORTALITY_ASSESSMENT,
  FETCH_MORTALITY_ASSESSMENTS,
  EDIT_MORTALITY_ASSESSMENT,
  CREATE_MORTALITY_ASSESSMENT,
  DELETE_MORTALITY_ASSESSMENT,
  FETCH_MORTALITY_ASSESSMENT,
  LOADING,
  SELECT_MORTALITY_ASSESSMENT_CONDITION,
} from "actions/types";
import { arrayToObjectIndexedById } from "reducers/utils";
import { findObjectByPropertyValue, removeKey } from "../../../utils";

export const defaultState = {
  mortalityAssessments: {},
  activeMortalityAssessmentId: null,
  activeMortalityAssessmentConditionId: null,
};

export default (state = defaultState, action) => {
  switch (action.type) {
    case SELECT_MORTALITY_ASSESSMENT:
      return { ...state, activeMortalityAssessmentId: action.payload };

    case SELECT_MORTALITY_ASSESSMENT_CONDITION:
      return { ...state, activeMortalityAssessmentConditionId: action.payload };

    case FETCH_MORTALITY_ASSESSMENT: {
      return {
        ...state,
        mortalityAssessments: {
          ...state.mortalityAssessments,
          [action.payload.id]: action.payload,
        },
      };
    }

    case FETCH_MORTALITY_ASSESSMENTS:
      console.log(FETCH_MORTALITY_ASSESSMENTS, action.payload);
      return { ...state, mortalityAssessments: arrayToObjectIndexedById(action.payload) };

    case EDIT_MORTALITY_ASSESSMENT:
    case CREATE_MORTALITY_ASSESSMENT: {
      return {
        ...state,
        mortalityAssessments: {
          ...state.mortalityAssessments,
          [action.payload.id]: action.payload,
        },
        activeMortalityAssessmentId: action.payload.id,
      };
    }

    case DELETE_MORTALITY_ASSESSMENT: {
      return {
        ...state,
        mortalityAssessments: removeKey(state.mortalityAssessments, action.payload),
      };
    }
    case LOADING:
      if (action.payload.mortalityAssessments) {
        return {
          ...state,
          mortalityAssessments: arrayToObjectIndexedById([]),
        };
      } else {
        return state;
      }

    default:
      return state;
  }
};

export const activeMortalityAssessmentSelector = ({ mortalityAssessments, activeMortalityAssessmentId }) => {
  if (activeMortalityAssessmentId) {
    return mortalityAssessments[activeMortalityAssessmentId];
  }
};

export const mortalityAssessmentsSelector = state => {
  return Object.values(state.mortalityAssessments);
};

export const activeMortalityAssessmentConditionSelector = ({
  mortalityAssessments,
  activeMortalityAssessmentId,
  activeMortalityAssessmentConditionId,
}) => {
  if (activeMortalityAssessmentConditionId) {
    if (mortalityAssessments[activeMortalityAssessmentId]) {
      const conditionList = mortalityAssessments[activeMortalityAssessmentId].condition_list;
      return findObjectByPropertyValue(conditionList, "id", activeMortalityAssessmentConditionId);
    }
  }
};
