import React, { useState } from "react";
import { connect } from "react-redux";
import { Formik } from "formik";
import { Form } from "react-bootstrap";
import { isEmpty } from "lodash";

import * as notifications from "../../../../notifications";
import { EditorField, FormCollapsible, DateInput, StateSelect, TextInput, Collapsible, Select } from "components/forms";
import { createAccountCarrierAppointment, editAccountCarrierAppointment } from "actions";
import {
  activeAccountIdSelector,
  activeCarrierAppointmentSelector,
  managementChoicesSelector,
  userChoicesSelector,
} from "reducers";
import { partial } from "utils";
import WideSaveButton from "components/buttons/WideSaveButton";
import SelectCheckboxes from "components/forms/SelectCheckboxes";

const CarrierAppointmentForm = ({ activeCarrierAppointment, ...props }) => {
  return (
    <Formik
      enableReinitialize
      initialValues={activeCarrierAppointment}
      onSubmit={async (values, { setSubmitting, setErrors }) => {
        const method = isEmpty(activeCarrierAppointment)
          ? props.createAccountCarrierAppointment
          : partial(props.editAccountCarrierAppointment, activeCarrierAppointment.id);

        values.account = props.activeAccountId;

        try {
          await method(values);
        } catch (error) {
          setErrors(error.response.data);
          notifications.error("Error editing Carrier Appointment");
        }
        setSubmitting(false);
      }}
    >
      {({ handleSubmit, values }) => {
        return (
          <FormCollapsible onSubmit={handleSubmit} title="Carrier Appointment Detail">
            <Form.Row>
              <Select
                label="Carrier"
                name="carrier"
                placeholder="Select Carrier"
                options={props.carrierChoices.getChoices()}
              />
              <DateInput label="Start Date" name="start_date" />
              <DateInput label="End Date" name="end_date" />
            </Form.Row>
            <WideSaveButton submitRef={props.submitRef} />
          </FormCollapsible>
        );
      }}
    </Formik>
  );
};

const mapStateToProps = state => {
  const choices = managementChoicesSelector(state);

  return {
    activeAccountId: activeAccountIdSelector(state),
    activeCarrierAppointment: activeCarrierAppointmentSelector(state) || {},
    carrierChoices: choices.carriers,
  };
};

export default connect(mapStateToProps, {
  createAccountCarrierAppointment,
  editAccountCarrierAppointment,
})(CarrierAppointmentForm);
