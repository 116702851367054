import React from "react";
import { connect } from "react-redux";
import { Card } from "react-bootstrap";
import MortalityAssessmentSummaryForm from "./MortalityAssessmentSummaryForm";
import { RecordsTable } from "components/table";
import FileInputsTable from "../FileInputsTable";
import HealthcareProvidersTable from "./HealthcareProvidersTable";
import DOBCheckTable from "./DOBCheckTable";
import SummaryForms from "./SummaryForms";
import HeightWeightTable from "./HeightWeightTable";

const MortalityAssessmentSummary = ({ ...props }) => {
  return (
    <div className={`section-card shadowed case-section`} style={{ marginTop: "5.5rem" }}>
      <Card className={"case-info"}>
        <Card.Body>
          <MortalityAssessmentSummaryForm />
          <RecordsTable
            title="File Inputs"
            actionButtons={[]}
            defaultSort={true}
            paginated={false}
            Table={FileInputsTable}
          />
          <RecordsTable
            title="Healthcare Providers"
            actionButtons={[]}
            defaultSort={true}
            paginated={false}
            Table={HealthcareProvidersTable}
          />
          <RecordsTable
            title="DOB Check"
            defaultSort={true}
            paginated={false}
            Table={DOBCheckTable}
            actionButtons={[]}
          />
          <SummaryForms />
          <RecordsTable
            title="Height & Weight"
            defaultSort={true}
            paginated={false}
            Table={HeightWeightTable}
            actionButtons={[]}
          />
        </Card.Body>
      </Card>
    </div>
  );
};

const mapStateToProps = state => {
  return {};
};

export default connect(mapStateToProps, {})(MortalityAssessmentSummary);
