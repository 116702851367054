import Table, { choiceCell } from "components/table";
import React from "react";
import { activeMortalityAssessmentSelector } from "reducers";
import { connect } from "react-redux";
import { PARAMETER_CATEGORY_CHOICES } from "../../../constants";
import IconLink from "components/IconLink";
import {
  createMortalityAssessmentCondition,
  selectMortalityAssessment,
  selectMortalityAssessmentCondition,
  showModal,
} from "actions";
import { FaCopy, FaEdit, FaTrash } from "react-icons/fa";
import { DELETE_CONDITION_LIST_MODAL, EDIT_CONDITION_LIST_MODAL } from "components/modals";

const ConditionListTable = ({ mortalityAssessmentInfo, ...props }) => {
  if (!mortalityAssessmentInfo) {
    // Still didn't fetch data
    return null;
  }

  const columns = [
    {
      Header: "Action",
      Cell: ({ row }) => {
        const id = row.original.id;
        const assessmentId = row.original.assessment_id;
        return (
          <>
            <IconLink
              Icon={FaEdit}
              iconConfig={{ className: "table--action-icon" }}
              tooltip="View / Edit"
              onClick={() => {
                props.selectMortalityAssessmentCondition(id);
                props.showModal(EDIT_CONDITION_LIST_MODAL, { id: id });
              }}
            />
            <IconLink
              Icon={FaTrash}
              iconConfig={{ className: "table--action-icon red" }}
              tooltip="Delete"
              onClick={() =>
                props.showModal(DELETE_CONDITION_LIST_MODAL, {
                  id: id,
                  assessmentId: assessmentId,
                })
              }
            />
            <IconLink
              Icon={FaCopy}
              iconConfig={{ className: "table--action-icon" }}
              tooltip="Copy"
              onClick={async () => {
                await props.createMortalityAssessmentCondition(row.original);
                props.selectMortalityAssessment(assessmentId);
              }}
            />
          </>
        );
      },
    },
    {
      Header: "Category",
      accessor: "category",
      Cell: choiceCell(PARAMETER_CATEGORY_CHOICES),
    },
    {
      Header: "Sub Category",
      accessor: "sub_category",
    },
    {
      Header: "Name",
      accessor: "name",
    },
    {
      Header: "ICD Code(s)",
      accessor: "icd_code",
    },
    {
      Header: "Extracted Key Word",
      accessor: "extracted_keyword",
    },
    {
      Header: "Extracted ICD Code",
      accessor: "extracted_icd_code",
    },
  ];

  return (
    <Table
      columns={columns}
      data={mortalityAssessmentInfo.condition_list || []}
      defaultSort={true}
      paginated={false}
      emptyMessage="No Conditions found"
    />
  );
};

const mapStateToProps = state => {
  return {
    mortalityAssessmentInfo: activeMortalityAssessmentSelector(state),
  };
};

export default connect(mapStateToProps, {
  showModal,
  createMortalityAssessmentCondition,
  selectMortalityAssessment,
  selectMortalityAssessmentCondition,
})(ConditionListTable);
