import React from "react";
import { connect } from "react-redux";
import { Card } from "react-bootstrap";
import MortalityAssessmentCurveForm from "./MortalityAssessmentCurveForm";
import MortalityAssessmentCurveGraphs from "./MortalityAssessmentCurveGraphs";
import MultiTab from "components/table/MultiTab";
import MortalityAssessmentCurveNumericTable from "./MortalityAssessmentCurveNumericTable";
import { activeMortalityAssessmentSelector } from "../../../../reducers";

const MortalityAssessmentCurve = ({ mortalityAssessmentInfo, ...props }) => {
  return (
    <div className={`section-card shadowed case-section`} style={{ marginTop: "5.5rem" }}>
      <Card className={"case-info"}>
        <Card.Body>
          <MortalityAssessmentCurveForm />
          <MultiTab
            Component1={MortalityAssessmentCurveNumericTable}
            Component2={MortalityAssessmentCurveGraphs}
            title1="Numeric Curve"
            title2="Graphs"
          />
        </Card.Body>
      </Card>
    </div>
  );
};

const mapStateToProps = state => {
  return {
    mortalityAssessmentInfo: activeMortalityAssessmentSelector(state),
  };
};

export default connect(mapStateToProps, {})(MortalityAssessmentCurve);
