import * as types from "../types";
import advisorhq from "../../apis/advisorhq";
import * as notifications from "../../notifications";

export const clearPredictions = () => async dispatch => {
  dispatch({ type: types.CLEAR_PREDICTIONS });
};

export const getPrediction = filters => async (dispatch, getState) => {
  dispatch({ type: types.LOADING, payload: { predictions: true } });
  try {
    const response = await advisorhq.get("/predictor/api/", {
      params: {
        ...filters,
      },
    });
    dispatch({ type: types.GET_PREDICTION, payload: response.data });
  } catch (error) {
    notifications.error(`Problem with prediction: ${error.response.data}`);
  }
  dispatch({ type: types.LOADING, payload: { predictions: false } });
};
