import { combineReducers } from "redux";

import authReducer, * as fromAuth from "./authReducer";
import modalReducer, * as fromModals from "./modalReducer";
import lifeSettlementCaseReducer from "./life_settlement";

import * as fromCases from "./life_settlement/cases";
import * as fromPagination from "./life_settlement/pagination";
import * as fromFilters from "./life_settlement/filters";
import * as fromFunders from "./life_settlement/funders";
import * as fromLoading from "./life_settlement/loading";
import * as fromManagement from "./life_settlement/management";
import * as fromInsured from "./life_settlement/management/insured";
import * as fromLifeExpectancies from "./life_settlement/management/lifeExpectancies";
import * as fromPolicy from "./life_settlement/management/policy";
import * as fromOwners from "./life_settlement/management/owners";
import * as fromHipaa from "./life_settlement/management/hipaa";
import * as fromMedicalRecords from "./life_settlement/management/medicalRecords";
import * as fromBeneficiaries from "./life_settlement/management/beneficiaries";
import * as fromInsuredDiligence from "./life_settlement/management/insuredDiligences";
import * as fromPolicyDocuments from "./life_settlement/management/policyDocuments";
import * as fromEntityDiligence from "./life_settlement/management/entityDiligences";
import * as fromPersonDiligence from "./life_settlement/management/personDiligences";
import * as fromSignerDiligence from "./life_settlement/management/signerDiligences";
import * as fromAuctions from "./life_settlement/management/auctions";
import * as fromEmailTemplates from "./life_settlement/management/emailTemplates";
import * as fromCaseFiles from "./life_settlement/management/caseFiles";
import * as fromAccounts from "./life_settlement/management/accounts";
import * as fromTasks from "./life_settlement/management/records";
import * as fromAgents from "./life_settlement/management/agents";
import * as fromActivityLog from "./life_settlement/management/activityLog";
import * as fromProducts from "./life_settlement/management/products";
import * as fromCarriers from "./life_settlement/management/carriers";
import * as fromLeads from "./life_settlement/management/leads";
import * as fromPredictions from "./life_settlement/management/predictions";
import * as fromParameters from "./life_settlement/management/parameters";
import * as fromSynonyms from "./life_settlement/management/synonyms";
import * as fromCaseTasks from "./life_settlement/management/caseTasks";
import * as fromClinicalResearch from "./life_settlement/management/clinicalResearch";
import * as fromMortalityAssessment from "./life_settlement/management/mortalityAssessments";
import * as fromWinflex from "./life_settlement/management/winflex";
import * as fromStates from "./life_settlement/management/states";

export default combineReducers({
  auth: authReducer,
  modals: modalReducer,
  lifeSettlement: lifeSettlementCaseReducer,
});

/* #### SELECTORS #### */

// auth
export const agenciesSelector = state => fromAuth.agenciesSelector(state.auth);

export const activeAgencySelector = state => fromAuth.activeAgencySelector(state.auth);

export const loginInfoSelector = state => fromAuth.loginInfoSelector(state.auth);

export const auctionAccessEnabledSelector = state => fromAuth.auctionAccessEnabledSelector(state.auth);

export const administratorEnabledSelector = state => fromAuth.administratorEnabledSelector(state.auth);

// modals
export const activeModalsSelector = state => fromModals.activeModalsSelector(state.modals);

// tables
export const activeTabSelector = state => fromFilters.activeTabSelector(state.lifeSettlement);

// agents
export const agentsChoicesSelector = state => state.lifeSettlement.management.agents;

export const userChoicesSelector = state => fromAgents.userChoicesSelector(state.lifeSettlement.management.agents);

// cases
export const casesSelector = state => fromCases.casesSelector(state.lifeSettlement.cases);

export const activeCaseIdSelector = state => state.lifeSettlement.management.caseId;

export const casePdfUrlSelector = state => state.lifeSettlement.other.casePdf;

export const mailFormDataSelector = state => state.lifeSettlement.cases;

// Return the active life settlement case, selecting it from the fetched cases
export const activeCaseSelector = state => {
  return state.lifeSettlement.selectedCase;
};

export const numberOfInsuredSelector = state => state.lifeSettlement.selectedCase.insured_count;

export const numberOfOwnersSelector = state => state.lifeSettlement.selectedCase.owners_count;

export const numberOfBeneficiariesSelector = state => state.lifeSettlement.selectedCase.beneficiaries_count;

export const numberOfPendingCaseFilesSelector = state => state.lifeSettlement.selectedCase.pending_case_files_count;

export const numberOfTasksSelector = state => state.lifeSettlement.selectedCase.tasks_count;

export const shouldSaveSelector = state => {
  return fromCases.shouldSaveSelector(state.lifeSettlement.cases);
};

// case files
export const activeCaseFileSelector = state =>
  fromCaseFiles.activeCaseFileSelector(state.lifeSettlement.management.caseFiles);

export const caseFilesSelector = state => fromCaseFiles.caseFilesSelector(state.lifeSettlement.management.caseFiles);

export const caseFilesCategorySelector = state =>
  fromCaseFiles.caseFilesCategorySelector(state.lifeSettlement.management.caseFiles);

export const caseFilesDescriptionSelector = state =>
  fromCaseFiles.caseFilesDescriptionSelector(state.lifeSettlement.management.caseFiles);

// doctors & APS order
export const doctorSelector = state => fromCaseFiles.doctorInfoSelector(state.lifeSettlement.management.caseFiles);

export const activeOrderFilesSelector = state =>
  fromCaseFiles.activeOrderFilesSelector(state.lifeSettlement.management.caseFiles);

export const policyRequestOptionsSelector = state =>
  fromCaseFiles.policyRequestOptionsSelector(state.lifeSettlement.management.caseFiles);

// management
export const managementChoicesSelector = state =>
  fromManagement.managementChoicesSelector(state.lifeSettlement.management);

export const accountsListSelector = state =>
  fromAccounts.accountsListSelector(state.lifeSettlement.management.accounts);

export const accountsSelector = state => fromAccounts.accountsSelector(state.lifeSettlement.management.accounts);

export const activeAccountSelector = state =>
  fromAccounts.activeAccountSelector(state.lifeSettlement.management.accounts);

export const activeAccountIdSelector = state =>
  fromAccounts.activeAccountIdSelector(state.lifeSettlement.management.accounts);

export const activeTaskSelector = state => fromAccounts.activeTaskSelector(state.lifeSettlement.management.accounts);

export const activeCarrierAppointmentSelector = state =>
  fromAccounts.activeCarrierAppointmentSelector(state.lifeSettlement.management.accounts);

export const activeLicenseSelector = state =>
  fromAccounts.activeLicenseSelector(state.lifeSettlement.management.accounts);

export const activeAgreementSelector = state =>
  fromAccounts.activeAgreementSelector(state.lifeSettlement.management.accounts);

export const activeHierarchySelector = state =>
  fromAccounts.activeHierarchySelector(state.lifeSettlement.management.accounts);

export const activeHierarchyIdSelector = state =>
  fromAccounts.activeHierarchyIdSelector(state.lifeSettlement.management.accounts);

export const activeSplitSelector = state => fromAccounts.activeSplitSelector(state.lifeSettlement.management.accounts);

export const activeSplitIdSelector = state =>
  fromAccounts.activeSplitIdSelector(state.lifeSettlement.management.accounts);

export const activeAffiliationSelector = state =>
  fromAccounts.activeAffiliationSelector(state.lifeSettlement.management.accounts);

export const accountCasesSelector = state =>
  fromAccounts.accountCasesSelector(state.lifeSettlement.management.accounts);

export const accountEntityChoicesSelector = state =>
  fromAccounts.accountEntityChoicesSelector(state.lifeSettlement.management.accounts);

export const availableEntitiesSelector = state =>
  fromAccounts.availableEntitiesSelector(state.lifeSettlement.management.accounts);

export const manageEntitySelector = state =>
  fromAccounts.manageEntitySelector(state.lifeSettlement.management.accounts);

export const manageEntityIdSelector = state =>
  fromAccounts.manageEntityIdSelector(state.lifeSettlement.management.accounts);

export const engagementActivitiesSelector = state =>
  fromAccounts.engagementActivitiesSelector(state.lifeSettlement.management.accounts);

export const selectedAccountsSelector = state =>
  fromAccounts.selectedAccountsSelector(state.lifeSettlement.management.accounts);

// policy
export const policySelector = state => fromPolicy.policySelector(state.lifeSettlement.management.policy);

// owners
export const ownerListSelector = state => fromOwners.ownerListSelector(state.lifeSettlement.management.owners);

export const activeOwnerSelector = state => fromOwners.activeOwnerSelector(state.lifeSettlement.management.owners);

export const ownerPersonsSelector = state => fromOwners.ownerPersonsSelector(state.lifeSettlement.management.owners);

export const ownerPersonSelector = (state, id) =>
  fromOwners.ownerPersonSelector(state.lifeSettlement.management.owners, id);

export const ownerEntitiesSelector = state => fromOwners.ownerEntitiesSelector(state.lifeSettlement.management.owners);

export const ownerEntitySelector = (state, id) =>
  fromOwners.ownerEntitySelector(state.lifeSettlement.management.owners, id);

export const ownerInsuredListSelector = (state, id) =>
  fromOwners.ownerInsuredListSelector(state.lifeSettlement.management.owners, id);

export const ownerSignersSelector = (state, entity_id) =>
  fromOwners.ownerSignersSelector(state.lifeSettlement.management.owners, entity_id);

export const activeOwnerIdSelector = state => fromOwners.activeOwnerIdSelector(state.lifeSettlement.management.owners);

export const numberOfSignersSelector = state => {
  const activeOwnerId = activeOwnerIdSelector(state);
  return fromOwners.numberOfSignersSelector(state.lifeSettlement.management.owners, activeOwnerId);
};

// insured
export const insuredSelector = (state, id) => fromInsured.insuredSelector(state.lifeSettlement.management.insured, id);

export const insuredListSelector = state => fromInsured.insuredListSelector(state.lifeSettlement.management.insured);

export const activeInsuredSelector = state =>
  fromInsured.activeInsuredSelector(state.lifeSettlement.management.insured);

export const activeInsuredIdSelector = state =>
  fromInsured.activeInsuredIdSelector(state.lifeSettlement.management.insured);

// life expectancies
export const lifeExpectancyChoicesSelector = state =>
  fromLifeExpectancies.choicesSelector(state.lifeSettlement.management.lifeExpectancies);

export const lifeExpectanciesSelector = state =>
  fromLifeExpectancies.lifeExpectanciesSelector(state.lifeSettlement.management.lifeExpectancies);

export const activeLifeExpectancySelector = state => {
  let activeCaseFile = activeCaseFileSelector(state);
  return fromLifeExpectancies.activeLifeExpectancySelector(
    activeCaseFile,
    state.lifeSettlement.management.lifeExpectancies,
  );
};

export const activeLifeExpectancyIdSelector = state => {
  const activeLifeExpectancy = activeLifeExpectancySelector(state);
  return activeLifeExpectancy && activeLifeExpectancy.id;
};

export const lifeExpectanciesForActiveInsuredSelector = state => {
  const activeInsuredId = activeInsuredIdSelector(state);
  return fromLifeExpectancies.lifeExpectanciesForActiveInsuredSelector(
    state.lifeSettlement.management.lifeExpectancies,
    activeInsuredId,
  );
};

export const twentyFirstMedicalConditionsSchemaSelector = state =>
  fromLifeExpectancies.twentyFirstMedicalConditionsSchemaSelector(state.lifeSettlement.management.lifeExpectancies);

export const predictiveMedicalConditionsSchemaSelector = state =>
  fromLifeExpectancies.predictiveMedicalConditionsSchemaSelector(state.lifeSettlement.management.lifeExpectancies);

export const extractedLifeExpectancyPDFdataSelector = state =>
  fromLifeExpectancies.extractedPDFdataSelector(state.lifeSettlement.management.lifeExpectancies);

// hipaa
export const hipaasSelector = state => fromHipaa.hipaasSelector(state.lifeSettlement.management.hipaa);

export const activeHipaaSelector = state => fromHipaa.activeHipaaSelector(state.lifeSettlement.management.hipaa);

export const hipaasForActiveInsuredSelector = state => {
  const activeInsuredId = activeInsuredIdSelector(state);
  return fromHipaa.hipaasForActiveInsuredSelector(state.lifeSettlement.management.hipaa, activeInsuredId);
};

// medical records
export const medicalRecordsSelector = state =>
  fromMedicalRecords.medicalRecordsSelector(state.lifeSettlement.management.medicalRecords);

export const activeMedicalRecordSelector = state =>
  fromMedicalRecords.activeMedicalRecordSelector(state.lifeSettlement.management.medicalRecords);

export const medicalRecordsForActiveInsuredSelector = state => {
  const activeInsuredId = activeInsuredIdSelector(state);
  return fromMedicalRecords.medicalRecordsForActiveInsuredSelector(
    state.lifeSettlement.management.medicalRecords,
    activeInsuredId,
  );
};

// beneficiaries
export const beneficiariesSelector = state =>
  fromBeneficiaries.beneficiariesSelector(state.lifeSettlement.management.beneficiaries);

export const beneficiaryEntitiesSelector = state =>
  fromBeneficiaries.beneficiaryEntitiesSelector(state.lifeSettlement.management.beneficiaries);

// insured diligences
export const activeInsuredDiligenceSelector = state =>
  fromInsuredDiligence.activeInsuredDiligenceSelector(state.lifeSettlement.management.insuredDiligences);

export const insuredDiligencesForActiveInsuredSelector = state => {
  const activeInsuredId = activeInsuredIdSelector(state);
  return fromInsuredDiligence.insuredDiligencesForActiveInsuredSelector(
    state.lifeSettlement.management.insuredDiligences,
    activeInsuredId,
  );
};

export const insuredDiligencesSelector = state =>
  fromInsuredDiligence.insuredDiligencesSelector(state.lifeSettlement.management.insuredDiligences);

// policy documents
export const activeBillingNoticeSelector = state =>
  fromPolicyDocuments.activeBillingNoticeSelector(state.lifeSettlement.management.policyDocuments);

export const billingNoticesSelector = state =>
  fromPolicyDocuments.billingNoticesSelector(state.lifeSettlement.management.policyDocuments);

export const activeChainOfTitleSelector = state =>
  fromPolicyDocuments.activeChainOfTitleSelector(state.lifeSettlement.management.policyDocuments);

export const chainOfTitlesSelector = state =>
  fromPolicyDocuments.chainOfTitlesSelector(state.lifeSettlement.management.policyDocuments);

export const activeIllustrationSelector = state =>
  fromPolicyDocuments.activeIllustrationSelector(state.lifeSettlement.management.policyDocuments);

export const illustrationsSelector = state =>
  fromPolicyDocuments.illustrationsSelector(state.lifeSettlement.management.policyDocuments);

export const activePolicyAndApplicationSelector = state =>
  fromPolicyDocuments.activePolicyAndApplicationSelector(state.lifeSettlement.management.policyDocuments);

export const policyAndApplicationsSelector = state =>
  fromPolicyDocuments.policyAndApplicationsSelector(state.lifeSettlement.management.policyDocuments);

export const activeStatementSelector = state =>
  fromPolicyDocuments.activeStatementSelector(state.lifeSettlement.management.policyDocuments);

export const statementsSelector = state =>
  fromPolicyDocuments.statementsSelector(state.lifeSettlement.management.policyDocuments);

export const activeVerificationOfCoverageSelector = state =>
  fromPolicyDocuments.activeVerificationOfCoverageSelector(state.lifeSettlement.management.policyDocuments);

export const verificationsOfCoverageSelector = state =>
  fromPolicyDocuments.verificationsOfCoverageSelector(state.lifeSettlement.management.policyDocuments);

// entity diligences
export const entityDiligencesSelector = state =>
  fromEntityDiligence.entityDiligencesSelector(state.lifeSettlement.management.entityDiligences);

export const activeEntityDiligenceSelector = state =>
  fromEntityDiligence.activeEntityDiligenceSelector(state.lifeSettlement.management.entityDiligences);

export const entityDiligencesForActiveOwnerSelector = state => {
  const activeEntityId = activeOwnerIdSelector(state);
  return fromEntityDiligence.entityDiligencesForActiveOwnerSelector(
    state.lifeSettlement.management.entityDiligences,
    activeEntityId,
  );
};

// person diligences
export const personDiligencesSelector = state =>
  fromPersonDiligence.personDiligencesSelector(state.lifeSettlement.management.personDiligences);

export const activePersonDiligenceSelector = state =>
  fromPersonDiligence.activePersonDiligenceSelector(state.lifeSettlement.management.personDiligences);

export const personDiligencesForActiveOwnerSelector = state => {
  const activePersonId = activeOwnerIdSelector(state);
  return fromPersonDiligence.personDiligencesForActiveOwnerSelector(
    state.lifeSettlement.management.personDiligences,
    activePersonId,
  );
};

// signer diligences
export const signerDiligencesSelector = state =>
  fromSignerDiligence.signerDiligencesSelector(state.lifeSettlement.management.signerDiligences);

export const activeSignerDiligenceSelector = state =>
  fromSignerDiligence.activeSignerDiligenceSelector(state.lifeSettlement.management.signerDiligences);

export const signerDiligencesForActiveOwnerSelector = state => {
  const activeEntityId = activeOwnerIdSelector(state);
  return fromSignerDiligence.signerDiligencesForActiveOwnerSelector(
    state.lifeSettlement.management.signerDiligences,
    activeEntityId,
  );
};

export const signerChoicesSelector = state => {
  const activeEntityId = activeOwnerIdSelector(state);
  return fromSignerDiligence.signerChoicesSelector(state.lifeSettlement.management.owners, activeEntityId);
};

export const activeSecondaryMarketClosingSelector = state =>
  fromPolicyDocuments.activeSecondaryMarketClosingSelector(state.lifeSettlement.management.policyDocuments);

export const secondaryMarketClosingsSelector = state =>
  fromPolicyDocuments.secondaryMarketClosingsSelector(state.lifeSettlement.management.policyDocuments);

export const activeTertiaryMarketClosingSelector = state =>
  fromPolicyDocuments.activeTertiaryMarketClosingSelector(state.lifeSettlement.management.policyDocuments);

export const tertiaryMarketClosingsSelector = state =>
  fromPolicyDocuments.tertiaryMarketClosingsSelector(state.lifeSettlement.management.policyDocuments);

// auctions
export const auctionSelector = state => fromAuctions.auctionSelector(state.lifeSettlement.management.auctions);

export const auctionIdSelector = state => fromAuctions.auctionIdSelector(state.lifeSettlement.management.auctions);

export const totalTradingCasesSelector = state =>
  fromAuctions.totalTradingCasesSelector(state.lifeSettlement.management.auctions);

export const tradeCasesSelector = state => fromAuctions.tradeCasesSelector(state.lifeSettlement.management.auctions);

export const tradeBlindCasesSelector = state =>
  fromAuctions.tradeBlindCasesSelector(state.lifeSettlement.management.auctions);

export const dpCasesSelector = state => fromAuctions.dpCasesSelector(state.lifeSettlement.management.auctions);

export const tbdCasesSelector = state => fromAuctions.tbdCasesSelector(state.lifeSettlement.management.auctions);

export const auctionChoicesSelector = state =>
  fromAuctions.auctionChoicesSelector(state.lifeSettlement.management.auctions);

export const displayGrossOfferSelector = state =>
  fromAuctions.displayGrossOfferSelector(state.lifeSettlement.management.auctions);

export const activeAuctionRoundSelector = state =>
  fromAuctions.activeAuctionRoundSelector(state.lifeSettlement.management.auctions);

export const auctionRoundsSelector = state =>
  fromAuctions.auctionRoundsSelector(state.lifeSettlement.management.auctions);

export const fundersForAuctionSelector = state =>
  fromAuctions.fundersForAuctionSelector(state.lifeSettlement.management.auctions);

export const funderWinningBidSelector = state => {
  const activeCaseId = state.lifeSettlement.management.caseId;
  return fromAuctions.funderWinningBidSelector(state.lifeSettlement.management.auctions, activeCaseId);
};

export const marketWinningBidSelector = state => {
  const activeCaseId = state.lifeSettlement.management.caseId;
  return fromAuctions.marketWinningBidSelector(state.lifeSettlement.management.auctions, activeCaseId);
};

export const activeAuctionActivitySelector = state =>
  fromAuctions.activeAuctionActivitySelector(state.lifeSettlement.management.auctions);

export const activeFunderAuctionActivitySelector = state =>
  fromAuctions.activeFunderAuctionActivitySelector(state.lifeSettlement.management.auctions);

export const activeMarketAuctionActivitySelector = state =>
  fromAuctions.activeMarketAuctionActivitySelector(state.lifeSettlement.management.auctions);

export const auctionActivitiesSelector = state =>
  fromAuctions.auctionActivitiesSelector(state.lifeSettlement.management.auctions);

export const marketAuctionActivitiesSelector = state =>
  fromAuctions.marketAuctionActivitiesSelector(state.lifeSettlement.management.auctions);

export const funderAuctionActivitiesSelector = state =>
  fromAuctions.funderAuctionActivitiesSelector(state.lifeSettlement.management.auctions);

export const selectedFundersSelector = state =>
  fromAuctions.selectedFundersSelector(state.lifeSettlement.management.auctions);

export const suggestedCCSelector = state => fromAuctions.suggestedCCSelector(state.lifeSettlement.management.auctions);

export const activeMarketActivityFilesSelector = state =>
  fromAuctions.activeMarketActivityFilesSelector(state.lifeSettlement.management.auctions);

export const funderSearchStringSelector = state =>
  fromAuctions.funderSearchStringSelector(state.lifeSettlement.management.auctions);

// funders
export const fundersSelector = state => fromFunders.fundersSelector(state.lifeSettlement.funders);

export const fundersActivityReportSelector = state =>
  fromFunders.fundersActivityReportSelector(state.lifeSettlement.funders);

export const fundersActivityReportTotalsSelector = state =>
  fromFunders.fundersActivityReportTotalsSelector(state.lifeSettlement.funders);

export const activeFunderCasesSelector = state => fromFunders.activeFunderCasesSelector(state.lifeSettlement.funders);

export const activeFunderCaseFitsSelector = state =>
  fromFunders.activeFunderCaseFitsSelector(state.lifeSettlement.funders);

export const activeFunderContactsSelector = state =>
  fromFunders.activeFunderContactsSelector(state.lifeSettlement.funders);

export const activeFunderContactIdSelector = state =>
  fromFunders.activeFunderContactIdSelector(state.lifeSettlement.funders);

export const activeFunderEntityIdSelector = state =>
  fromFunders.activeFunderEntityIdSelector(state.lifeSettlement.funders);

export const activeFunderEntitySelector = state => fromFunders.activeFunderEntitySelector(state.lifeSettlement.funders);

export const activeSecurityIntermediaryIdSelector = state =>
  fromFunders.activeSecurityIntermediaryIdSelector(state.lifeSettlement.funders);

export const activeTradingDocumentIdSelector = state =>
  fromFunders.activeTradingDocumentIdSelector(state.lifeSettlement.funders);

export const activeFunderEntitiesSelector = state =>
  fromFunders.activeFunderEntitiesSelector(state.lifeSettlement.funders);

export const activeClosingFunderEntitiesSelector = state =>
  fromFunders.activeClosingFunderEntitiesSelector(state.lifeSettlement.funders);

export const activeTradingFunderEntitiesSelector = state =>
  fromFunders.activeTradingFunderEntitiesSelector(state.lifeSettlement.funders);

export const activeFunderSelector = state => fromFunders.activeFunderSelector(state.lifeSettlement.funders);

export const fundersSortBySelector = state => fromFilters.fundersSortBySelector(state.lifeSettlement.filters);

export const funderFiltersSelector = state => {
  return fromFunders.funderFiltersSelector(state.lifeSettlement.funders);
};

// providers
// export const providersSelector = state =>
//  fromFunders.providersSelector(state.lifeSettlement.funders);

// loading
export const loadingSelector = state => fromLoading.loadingSelector(state.lifeSettlement.loading);

export const loadingLeSelector = state => fromLoading.loadingLeSelector(state.lifeSettlement.loading);

export const loadingLoginSelector = state => fromLoading.loadingLoginSelector(state.lifeSettlement.loading);

export const loadingCasesSelector = state => fromLoading.loadingCasesSelector(state.lifeSettlement.loading);

export const loadingProductsSelector = state => fromLoading.loadingProductsSelector(state.lifeSettlement.loading);

export const loadingCarriersSelector = state => fromLoading.loadingCarriersSelector(state.lifeSettlement.loading);

export const loadingAccountsSelector = state => fromLoading.loadingAccountsSelector(state.lifeSettlement.loading);

export const loadingLeadsSelector = state => fromLoading.loadingLeadsSelector(state.lifeSettlement.loading);

export const loadingFundersSelector = state => fromLoading.loadingFundersSelector(state.lifeSettlement.loading);

export const loadingFunderCasesSelector = state => fromLoading.loadingFunderCasesSelector(state.lifeSettlement.loading);

export const loadingFunderCaseFitsSelector = state =>
  fromLoading.loadingFunderCaseFitsSelector(state.lifeSettlement.loading);

export const loadingFunderContactsSelector = state =>
  fromLoading.loadingFunderContactsSelector(state.lifeSettlement.loading);

export const loadingFunderEntitiesSelector = state =>
  fromLoading.loadingFunderEntitiesSelector(state.lifeSettlement.loading);

export const loadingInsuredSelector = state => fromLoading.loadingInsuredSelector(state.lifeSettlement.loading);

export const loadingCaseFilesSelector = state => fromLoading.loadingCaseFilesSelector(state.lifeSettlement.loading);

export const loadingRecordsSelector = state => fromLoading.loadingRecordsSelector(state.lifeSettlement.loading);

export const loadingAccountHierarchiesSelector = state =>
  fromLoading.loadingAccountHierarchiesSelector(state.lifeSettlement.loading);

export const loadingPredictionsSelector = state => fromLoading.loadingPredictionsSelector(state.lifeSettlement.loading);

// stored filters

export const storedCasesFiltersSelector = state => {
  return fromFilters.storedCasesFiltersSelector(state.lifeSettlement.filters);
};

export const storedTradingFiltersSelector = state => {
  return fromFilters.storedTradingFiltersSelector(state.lifeSettlement.filters);
};

// records filters
export const activeRecordSelector = state => {
  const activeTab = fromFilters.activeTabSelector(state.lifeSettlement);
  return fromTasks.activeRecordSelector(state.lifeSettlement.management.records, activeTab);
};

export const tabBasedRecordsSelector = state => {
  const activeTab = fromFilters.activeTabSelector(state.lifeSettlement);
  return fromTasks.tabBasedRecordsSelector(state.lifeSettlement.management.records, activeTab);
};

export const storedRecordsFiltersSelector = state => {
  return fromFilters.storedRecordsFiltersSelector(state.lifeSettlement.filters);
};

export const storedCaseRecordsFiltersSelector = state => {
  return fromFilters.storedCaseRecordsFiltersSelector(state.lifeSettlement.filters);
};

export const storedCaseRequirementRecordsFiltersSelector = state => {
  return fromFilters.storedCaseRequirementRecordsFiltersSelector(state.lifeSettlement.filters);
};

export const storedAccountRecordsFiltersSelector = state => {
  return fromFilters.storedAccountRecordsFiltersSelector(state.lifeSettlement.filters);
};

export const storedLeadRecordsFiltersSelector = state => {
  return fromFilters.storedLeadRecordsFiltersSelector(state.lifeSettlement.filters);
};

export const storedLeFiltersSelector = state => {
  return fromFilters.storedLeFiltersSelector(state.lifeSettlement.filters);
};

export const storedAccountsFiltersSelector = state => {
  return fromFilters.storedAccountsFiltersSelector(state.lifeSettlement.filters);
};

export const storedLeadsFiltersSelector = state => {
  return fromFilters.storedLeadsFiltersSelector(state.lifeSettlement.filters);
};

export const storedParametersFiltersSelector = state => {
  return fromFilters.storedParametersFiltersSelector(state.lifeSettlement.filters);
};

// pagination

export const pageSizeSelector = state => {
  return fromPagination.pageSizeSelector(state.lifeSettlement.pagination);
};

// pagination cases

export const caseCountSelector = state => {
  return fromPagination.caseCountSelector(state.lifeSettlement.pagination);
};

export const tradingCasesCountSelector = state => {
  return fromPagination.tradingCasesCountSelector(state.lifeSettlement.pagination);
};

export const nextPageCaseSelector = state => {
  return fromPagination.nextPageCaseSelector(state.lifeSettlement.pagination);
};

export const previousPageCaseSelector = state => {
  return fromPagination.previousPageCaseSelector(state.lifeSettlement.pagination);
};

export const currentPageCaseSelector = state => {
  return fromPagination.currentPageCaseSelector(state.lifeSettlement.pagination);
};

export const getPagesCaseCount = state => {
  return fromPagination.getPagesCaseCount(state.lifeSettlement.pagination);
};

// pagination les

export const nextPageLeSelector = state => {
  return fromPagination.nextPageLeSelector(state.lifeSettlement.pagination);
};

export const previousPageLeSelector = state => {
  return fromPagination.previousPageLeSelector(state.lifeSettlement.pagination);
};

export const currentPageLeSelector = state => {
  return fromPagination.currentPageLeSelector(state.lifeSettlement.pagination);
};

export const leCountSelector = state => {
  return fromPagination.leCountSelector(state.lifeSettlement.pagination);
};

export const getPagesLeCount = state => {
  return fromPagination.getPagesLeCount(state.lifeSettlement.pagination);
};

// pagination Records

export const nextPageRecordsSelector = state => {
  return fromPagination.nextPageRecordsSelector(state.lifeSettlement.pagination);
};

export const previousPageRecordsSelector = state => {
  return fromPagination.previousPageRecordsSelector(state.lifeSettlement.pagination);
};

export const currentPageRecordsSelector = state => {
  return fromPagination.currentPageRecordsSelector(state.lifeSettlement.pagination);
};

export const recordsCountSelector = state => {
  return fromPagination.recordsCountSelector(state.lifeSettlement.pagination);
};

export const getPagesRecordsCount = state => {
  return fromPagination.getPagesRecordsCount(state.lifeSettlement.pagination);
};

// additional pagination records

export const nextPageCaseRecordsSelector = state => {
  return fromPagination.nextPageCaseRecordsSelector(state.lifeSettlement.pagination);
};

export const previousPageCaseRecordsSelector = state => {
  return fromPagination.previousPageCaseRecordsSelector(state.lifeSettlement.pagination);
};

export const currentPageCaseRecordsSelector = state => {
  return fromPagination.currentPageCaseRecordsSelector(state.lifeSettlement.pagination);
};

export const caseRecordsCountSelector = state => {
  return fromPagination.caseRecordsCountSelector(state.lifeSettlement.pagination);
};

export const getPagesCaseRecordsCount = state => {
  return fromPagination.getPagesCaseRecordsCount(state.lifeSettlement.pagination);
};

export const nextPageCaseRequirementRecordsSelector = state => {
  return fromPagination.nextPageCaseRequirementRecordsSelector(state.lifeSettlement.pagination);
};

export const previousPageCaseRequirementRecordsSelector = state => {
  return fromPagination.previousPageCaseRequirementRecordsSelector(state.lifeSettlement.pagination);
};

export const currentPageCaseRequirementRecordsSelector = state => {
  return fromPagination.currentPageCaseRequirementRecordsSelector(state.lifeSettlement.pagination);
};

export const caseRequirementRecordsCountSelector = state => {
  return fromPagination.caseRequirementRecordsCountSelector(state.lifeSettlement.pagination);
};

export const getPagesCaseRequirementRecordsCount = state => {
  return fromPagination.getPagesCaseRequirementRecordsCount(state.lifeSettlement.pagination);
};

export const nextPageAccountRecordsSelector = state => {
  return fromPagination.nextPageAccountRecordsSelector(state.lifeSettlement.pagination);
};

export const previousPageAccountRecordsSelector = state => {
  return fromPagination.previousPageAccountRecordsSelector(state.lifeSettlement.pagination);
};

export const currentPageAccountRecordsSelector = state => {
  return fromPagination.currentPageAccountRecordsSelector(state.lifeSettlement.pagination);
};

export const accountRecordsCountSelector = state => {
  return fromPagination.accountRecordsCountSelector(state.lifeSettlement.pagination);
};

export const getPagesAccountRecordsCount = state => {
  return fromPagination.getPagesAccountRecordsCount(state.lifeSettlement.pagination);
};

export const nextPageLeadRecordsSelector = state => {
  return fromPagination.nextPageLeadRecordsSelector(state.lifeSettlement.pagination);
};

export const previousPageLeadRecordsSelector = state => {
  return fromPagination.previousPageLeadRecordsSelector(state.lifeSettlement.pagination);
};

export const currentPageLeadRecordsSelector = state => {
  return fromPagination.currentPageLeadRecordsSelector(state.lifeSettlement.pagination);
};

export const leadRecordsCountSelector = state => {
  return fromPagination.leadRecordsCountSelector(state.lifeSettlement.pagination);
};

export const getPagesLeadRecordsCount = state => {
  return fromPagination.getPagesLeadRecordsCount(state.lifeSettlement.pagination);
};

// pagination Accounts

export const nextPageAccountsSelector = state => {
  return fromPagination.nextPageAccountsSelector(state.lifeSettlement.pagination);
};

export const previousPageAccountsSelector = state => {
  return fromPagination.previousPageAccountsSelector(state.lifeSettlement.pagination);
};

export const currentPageAccountsSelector = state => {
  return fromPagination.currentPageAccountsSelector(state.lifeSettlement.pagination);
};

export const accountsCountSelector = state => {
  return fromPagination.accountsCountSelector(state.lifeSettlement.pagination);
};

export const getPagesAccountsCount = state => {
  return fromPagination.getPagesAccountsCount(state.lifeSettlement.pagination);
};

// pagination Leads

export const nextPageLeadsSelector = state => {
  return fromPagination.nextPageLeadsSelector(state.lifeSettlement.pagination);
};

export const previousPageLeadsSelector = state => {
  return fromPagination.previousPageLeadsSelector(state.lifeSettlement.pagination);
};

export const currentPageLeadsSelector = state => {
  return fromPagination.currentPageLeadsSelector(state.lifeSettlement.pagination);
};

export const leadsCountSelector = state => {
  return fromPagination.leadsCountSelector(state.lifeSettlement.pagination);
};

export const getPagesLeadsCount = state => {
  return fromPagination.getPagesLeadsCount(state.lifeSettlement.pagination);
};

//

export const sortBySelector = state => {
  return fromFilters.sortBySelector(state.lifeSettlement.filters);
};

export const initialPageResultsSelector = state => {
  if (state.lifeSettlement.filters.activeTab === "cases") {
    return fromFilters.initialCasesPageResultsSelector(state.lifeSettlement.pagination);
  } else if (state.lifeSettlement.filters.activeTab === "lifeexpectancy") {
    return fromFilters.initialLesPageResultsSelector(state.lifeSettlement.pagination);
  } else if (state.lifeSettlement.filters.activeTab === "tasks") {
    return fromPagination.initialRecordsPageResultsSelector(state.lifeSettlement.pagination);
  } else if (state.lifeSettlement.filters.activeTab === "case-tasks") {
    return fromPagination.initialCaseRecordsPageResultsSelector(state.lifeSettlement.pagination);
  } else if (state.lifeSettlement.filters.activeTab === "case-requirement-tasks") {
    return fromPagination.initialCaseRequirementRecordsPageResultsSelector(state.lifeSettlement.pagination);
  } else if (state.lifeSettlement.filters.activeTab === "account-tasks") {
    return fromPagination.initialAccountRecordsPageResultsSelector(state.lifeSettlement.pagination);
  } else if (state.lifeSettlement.filters.activeTab === "lead-tasks") {
    return fromPagination.initialLeadRecordsPageResultsSelector(state.lifeSettlement.pagination);
  } else if (state.lifeSettlement.filters.activeTab === "accounts") {
    return fromFilters.initialAccountsPageResultsSelector(state.lifeSettlement.pagination);
  } else if (state.lifeSettlement.filters.activeTab === "leads") {
    return fromFilters.initialLeadsPageResultsSelector(state.lifeSettlement.pagination);
  } else if (state.lifeSettlement.filters.activeTab === "parameters") {
    return fromFilters.initialParametersPageResultsSelector(state.lifeSettlement.pagination);
  } else {
    return 0;
  }
};

export const relatedLeSelector = state => {
  return fromLifeExpectancies.relatedLeSelector(state.lifeSettlement.management.lifeExpectancies);
};

export const currentLETableIndexSelector = state => {
  return fromLifeExpectancies.currentLETableIndexSelector(state.lifeSettlement.management.lifeExpectancies);
};

// records
export const recordsSelector = state => {
  return fromTasks.recordsSelector(state.lifeSettlement.management.records);
};

// records
export const activeRecordTypeSelector = state => {
  return fromTasks.activeRecordTypeSelector(state.lifeSettlement.management.records);
};

export const caseRecordsSelector = state => {
  return fromTasks.caseRecordsSelector(state.lifeSettlement.management.records);
};

export const accountRecordsSelector = state => {
  return fromTasks.accountRecordsSelector(state.lifeSettlement.management.records);
};

export const caseRequirementRecordsSelector = state => {
  return fromTasks.caseRequirementRecordsSelector(state.lifeSettlement.management.records);
};

export const leadRecordsSelector = state => {
  return fromTasks.leadRecordsSelector(state.lifeSettlement.management.records);
};

export const pendingRecordsCountSelector = state => {
  return fromPagination.pendingRecordsCountSelector(state.lifeSettlement.pagination);
};

export const pastDueRecordsCountSelector = state => {
  return fromPagination.pastDueRecordsCountSelector(state.lifeSettlement.pagination);
};

export const pendingCaseRecordsCountSelector = state => {
  return fromPagination.pendingCaseRecordsCountSelector(state.lifeSettlement.pagination);
};

export const pastDueCaseRecordsCountSelector = state => {
  return fromPagination.pastDueCaseRecordsCountSelector(state.lifeSettlement.pagination);
};

export const pendingCaseRequirementRecordsCountSelector = state => {
  return fromPagination.pendingCaseRequirementRecordsCountSelector(state.lifeSettlement.pagination);
};

export const pastDueCaseRequirementRecordsCountSelector = state => {
  return fromPagination.pastDueCaseRequirementRecordsCountSelector(state.lifeSettlement.pagination);
};

export const pendingAccountRecordsCountSelector = state => {
  return fromPagination.pendingAccountRecordsCountSelector(state.lifeSettlement.pagination);
};

export const pastDueAccountRecordsCountSelector = state => {
  return fromPagination.pastDueAccountRecordsCountSelector(state.lifeSettlement.pagination);
};

export const pendingLeadRecordsCountSelector = state => {
  return fromPagination.pendingLeadRecordsCountSelector(state.lifeSettlement.pagination);
};

export const pastDueLeadRecordsCountSelector = state => {
  return fromPagination.pastDueLeadRecordsCountSelector(state.lifeSettlement.pagination);
};

// activity log
export const activityLogSelector = state => {
  return fromActivityLog.activityLogSelector(state.lifeSettlement.management.activityLog);
};

// product
export const activeProductSelector = state =>
  fromProducts.activeProductSelector(state.lifeSettlement.management.products);

export const productsSelector = state => fromProducts.productsSelector(state.lifeSettlement.management.products);

export const productChoicesSelector = state =>
  fromProducts.productChoicesSelector(state.lifeSettlement.management.products);

// carrier
export const carriersSelector = state => fromCarriers.carriersSelector(state.lifeSettlement.management.carriers);

export const activeCarrierSelector = state =>
  fromCarriers.activeCarrierSelector(state.lifeSettlement.management.carriers);

export const activeCarrierFileSelector = state =>
  fromCarriers.activeCarrierFileSelector(state.lifeSettlement.management.carriers);

// leads

export const leadsSelector = state => fromLeads.leadsSelector(state.lifeSettlement.management.leads);

export const activeLeadSelector = state => fromLeads.activeLeadSelector(state.lifeSettlement.management.leads);

export const activeLeadIdSelector = state => fromLeads.activeLeadIdSelector(state.lifeSettlement.management.leads);

export const leadsListSelector = state => fromLeads.leadsListSelector(state.lifeSettlement.management.leads);

export const activeLeadTaskSelector = state => fromLeads.activeLeadTaskSelector(state.lifeSettlement.management.leads);

export const leadTasksSelector = state => fromLeads.leadTasksSelector(state.lifeSettlement.management.leads);

export const selectedLeadsSelector = state => fromLeads.selectedLeadsSelector(state.lifeSettlement.management.leads);

// predictions
export const predictionsSelector = state =>
  fromPredictions.predictionsSelector(state.lifeSettlement.management.predictions);

// riskx parameters
export const parametersSelector = state =>
  fromParameters.parametersSelector(state.lifeSettlement.management.parameters);

export const activeParameterSelector = state =>
  fromParameters.activeParameterSelector(state.lifeSettlement.management.parameters);

export const activeParameterIdSelector = state =>
  fromParameters.activeParameterIdSelector(state.lifeSettlement.management.parameters);

export const nextPageParametersSelector = state =>
  fromPagination.nextPageParametersSelector(state.lifeSettlement.pagination);

export const previousPageParametersSelector = state =>
  fromPagination.previousPageParametersSelector(state.lifeSettlement.pagination);

export const currentPageParametersSelector = state =>
  fromPagination.currentPageParametersSelector(state.lifeSettlement.pagination);

export const parametersCountSelector = state => fromPagination.parametersCountSelector(state.lifeSettlement.pagination);

export const getPagesParametersCount = state => fromPagination.getPagesParametersCount(state.lifeSettlement.pagination);

// riskx synonyms
export const synonymsSelector = state => fromSynonyms.synonymsSelector(state.lifeSettlement.management.synonyms);

export const parameterSynonymsSelector = state =>
  fromSynonyms.parameterSynonymsSelector(state.lifeSettlement.management.synonyms);

export const activeParameterSynonymSelector = state =>
  fromSynonyms.activeParameterSynonymSelector(state.lifeSettlement.management.synonyms);

export const activeSynonymSelector = state =>
  fromSynonyms.activeSynonymSelector(state.lifeSettlement.management.synonyms);

export const nextPageSynonymsSelector = state =>
  fromPagination.nextPageSynonymsSelector(state.lifeSettlement.pagination);

export const previousPageSynonymsSelector = state =>
  fromPagination.previousPageSynonymsSelector(state.lifeSettlement.pagination);

export const currentPageSynonymsSelector = state =>
  fromPagination.currentPageSynonymsSelector(state.lifeSettlement.pagination);

export const synonymsCountSelector = state => fromPagination.synonymsCountSelector(state.lifeSettlement.pagination);

export const getPagesSynonymsCount = state => fromPagination.getPagesSynonymsCount(state.lifeSettlement.pagination);

// clinical research files
export const clinicalResearchSelector = state =>
  fromClinicalResearch.clinicalResearchSelector(state.lifeSettlement.management.clinicalResearch);

export const activeClinicalResearchSelector = state =>
  fromClinicalResearch.activeClinicalResearchSelector(state.lifeSettlement.management.clinicalResearch);

// case tasks
export const activeCaseTaskSelector = state =>
  fromCaseTasks.activeCaseTaskSelector(state.lifeSettlement.management.caseTasks);

// mortality assessments
export const mortalityAssessmentsSelector = state =>
  fromMortalityAssessment.mortalityAssessmentsSelector(state.lifeSettlement.management.mortalityAssessments);

export const activeMortalityAssessmentSelector = state =>
  fromMortalityAssessment.activeMortalityAssessmentSelector(state.lifeSettlement.management.mortalityAssessments);

export const activeMortalityAssessmentConditionSelector = state =>
  fromMortalityAssessment.activeMortalityAssessmentConditionSelector(
    state.lifeSettlement.management.mortalityAssessments,
  );

// email templates
export const emailTemplateSelector = state =>
  fromEmailTemplates.emailTemplateSelector(state.lifeSettlement.management.emailTemplates);

export const activeEmailTemplateSelector = state =>
  fromEmailTemplates.activeEmailTemplateSelector(state.lifeSettlement.management.emailTemplates);

export const activeEmailTemplateTypeSelector = state =>
  fromEmailTemplates.activeEmailTemplateTypeSelector(state.lifeSettlement.management.emailTemplates);

// winflex
export const winflexChoicesSelector = state =>
  fromWinflex.winflexChoicesSelector(state.lifeSettlement.management.winflex);

// states
export const statesSelector = state => fromStates.statesSelector(state.lifeSettlement.management.states);

export const activeStateSelector = state => fromStates.activeStateSelector(state.lifeSettlement.management.states);

export const activeStateBrokerFileSelector = state =>
  fromStates.activeStateBrokerFileSelector(state.lifeSettlement.management.states);

export const activeStateProviderFileSelector = state =>
  fromStates.activeStateProviderFileSelector(state.lifeSettlement.management.states);
