import React from "react";
import { Form, Col, InputGroup } from "react-bootstrap";
import { useField, useFormikContext } from "formik";
import ReactSelect from "react-select";

import { getValidationClassName } from "../validation";
import FieldErrors from "../FieldErrors";
import { getViewMode } from "../../../utils";
import { getCustomStyles } from "./customStyles";

const Select = ({ label, options = [], emptyValue = "", md = "", submitRef = undefined, ...props }) => {
  const [field, meta] = useField(props);
  const { setFieldValue } = useFormikContext();
  const isViewMode = getViewMode();
  const isDisabled = props.disabled || isViewMode;

  // Search value into possibly nested options (for now we search up to 1 nested level)
  const findOption = value => {
    let flattenedOpts = [];

    options.forEach(opt => {
      if (opt.options) {
        flattenedOpts = [...flattenedOpts, ...opt.options];
      } else {
        flattenedOpts.push(opt);
      }
    });
    return flattenedOpts.find(opt => opt.value === value);
  };

  let initialValue;
  if (field.value !== undefined && field.value !== emptyValue) {
    initialValue = findOption(field.value);
  } else {
    if (props.defaultValue !== undefined) {
      initialValue = findOption(props.defaultValue);
    } else {
      initialValue = emptyValue;
    }
  }

  const defaultOnChange = option => {
    let value;
    if (option === null) {
      value = props.defaultValue !== undefined ? props.defaultValue : emptyValue;
    } else {
      value = option.value;
    }
    setFieldValue(field.name, value);
    if (props.onChangeCallback) {
      props.onChangeCallback(option);
    }
  };

  const customStyles = getCustomStyles(isDisabled);
  let placeholder = isDisabled ? "---" : props.placeholder;

  return (
    <Form.Group
      style={{ flexWrap: "no-wrap!important" }}
      as={Col}
      sm="12"
      className={`col-md${md && "-" + md} padding0`}
    >
      {props.append ? props.append : null}
      <InputGroup style={{ flexWrap: "nowrap!important" }}>
        <InputGroup.Prepend>
          <InputGroup.Text>
            <div style={{ marginLeft: 1, marginRight: 3 }}>{label}</div>
            {props.icon}
          </InputGroup.Text>
        </InputGroup.Prepend>
        <div style={{ flexGrow: "1" }}>
          <ReactSelect
            {...props}
            placeholder={placeholder}
            maxMenuHeight={200}
            name={field.name}
            options={options}
            value={initialValue}
            onChange={props.onChange || defaultOnChange}
            className={`select ${getValidationClassName(meta)}`}
            isClearable
            menuPlacement="auto"
            styles={customStyles}
            isDisabled={isDisabled || false}
            ref={submitRef}
            classNamePrefix="case-form-select"
          />
        </div>
        <FieldErrors meta={meta} />
      </InputGroup>
    </Form.Group>
  );
};

export default Select;
