import advisorhq from "apis/advisorhq";
import { hideModal, showModal } from "./modals";
import { LOADING_MODAL } from "../components/modals";
import * as notifications from "notifications.js";

export * from "./auth";
export * from "./modals";
export * from "./generic";
export * from "./life_settlement";
export * from "./life_settlement/cases";
export * from "./life_settlement/policy";
export * from "./life_settlement/insured";
export * from "./life_settlement/owners";
export * from "./life_settlement/lifeExpectancy";
export * from "./life_settlement/beneficiaries";
export * from "./life_settlement/auctions";
export * from "./life_settlement/funders";
export * from "./life_settlement/emails";
export * from "./life_settlement/agents";
export * from "./life_settlement/emailTemplates";
export * from "./life_settlement/caseFiles";
export * from "./life_settlement/generateCasePdf";
export * from "./life_settlement/generateAPSOrderPdf";
export * from "./life_settlement/generatePolicyRequest";
export * from "./life_settlement/generateQuestHealthRequest";
export * from "./life_settlement/records";
export * from "./life_settlement/activityLog";
export * from "./life_settlement/products";
export * from "./life_settlement/carriers";
export * from "./life_settlement/accounts";
export * from "./life_settlement/leads";
export * from "./life_settlement/predictions";
export * from "./life_settlement/caseTasks";
export * from "./riskx/parameters";
export * from "./riskx/mortalityAssessments";
export * from "./pagination/pagination";
export * from "./life_settlement/states";

export const downloadFile = (url, filename) => async dispatch => {
  dispatch(
    showModal(LOADING_MODAL, {
      message: "Downloading file...",
    }),
  );

  try {
    const response = await advisorhq.get(url, { responseType: "blob" });
    const link = document.createElement("a");
    link.href = window.URL.createObjectURL(new Blob([response.data]));
    link.setAttribute("download", filename);
    document.body.appendChild(link);
    link.click();
  } catch (e) {
    notifications.error("Error downloading file");
  }

  dispatch(hideModal(LOADING_MODAL));
};
