import React from "react";
import { connect } from "react-redux";
import { Formik } from "formik";

import {
  Collapsible,
  DateInput,
  DateTimeInput,
  HyperLinkText,
  SSNInput,
  SubmitButton,
  TextInput,
} from "components/forms";
import { Form } from "react-bootstrap";
import { activeMortalityAssessmentSelector, shouldSaveSelector } from "reducers";
import GenderSelect from "../../../../components/forms/GenderSelect";
import { partial } from "../../../../utils";
import * as notifications from "notifications";
import { editMortalityAssessment, unsetShouldSave } from "actions";

const MortalityAssessmentSummaryForm = ({ mortalityAssessmentInfo, ...props }) => {
  if (!mortalityAssessmentInfo) {
    return null;
  }

  const submitRef = React.useRef();
  React.useEffect(() => {
    if (props.shouldSave) {
      submitRef.current.click();
    }
  }, [props.shouldSave]);

  return (
    <Formik
      enableReinitialize
      initialValues={{
        ...mortalityAssessmentInfo,
      }}
      onSubmit={async (values, { setSubmitting, setErrors }) => {
        setSubmitting(false);
        const method = partial(props.editMortalityAssessment, mortalityAssessmentInfo.id);

        try {
          await method(values);
          notifications.success("Mortality Assessment saved successfully");
        } catch (error) {
          setErrors(error.response.data);
          notifications.error("Error editing Mortality Assessment");
        }
        props.unsetShouldSave();
        setSubmitting(false);
      }}
    >
      {({ handleSubmit, values, setFieldValue }) => {
        return (
          <Form onSubmit={handleSubmit} {...props}>
            <Collapsible title="Overview" style={{ marginBottom: 10, paddingBottom: 5 }}>
              <Form.Row>
                <TextInput label="First Name" name="first_name" md={3} />
                <TextInput label="Last Name" name="last_name" md={3} />
                <GenderSelect md={2} />
                <DateInput label="Date of Birth" name="date_of_birth" />
                <SSNInput />
              </Form.Row>
              <Form.Row>
                <HyperLinkText
                  label="Case ID"
                  name="case_submission_id"
                  url={`/cases/${mortalityAssessmentInfo.case_id}/policy/`}
                  copy
                />
                <TextInput label="MA ID" name="ma_id" disabled />
                <TextInput label="MR Start Date" name="start_date" />
                <TextInput label="MR End Date" name="end_date" />
              </Form.Row>
              <Form.Row>
                <TextInput label="MA Age" name="age" />
                <TextInput label="Mortality Table" name="mortality_table" />
                <TextInput label="Mortality MM" name="mortality_mm" />
                <TextInput label="Mortality Wear Off" name="mortality_wear_off" />
              </Form.Row>
              <Form.Row>
                <TextInput label="Flat Extra Rating" name="flat_extra_rating" />
                <TextInput label="Flat Extra Months" name="flat_extra_months" />
                <TextInput label="Mean Months" name="mean_months" />
                <TextInput label="Median Months" name="median_months" />
              </Form.Row>
              <Form.Row>
                <DateTimeInput label="Date Created" name="date_created" disabled />
                <TextInput label="Created By" name="created_by_name" disabled />
                <DateTimeInput label="Last Edit Date" name="last_edit_date" disabled />
                <TextInput label="Last Edited By" name="last_edited_by_name" disabled />
              </Form.Row>
              <SubmitButton innerRef={submitRef} style={{ display: "none" }} />
            </Collapsible>
          </Form>
        );
      }}
    </Formik>
  );
};

const mapStateToProps = state => {
  return {
    shouldSave: shouldSaveSelector(state),
    mortalityAssessmentInfo: activeMortalityAssessmentSelector(state),
  };
};

export default connect(mapStateToProps, {
  editMortalityAssessment,
  unsetShouldSave,
})(MortalityAssessmentSummaryForm);
