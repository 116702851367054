import React, { useRef } from "react";
import { connect } from "react-redux";
import { Formik } from "formik";
import { Form } from "react-bootstrap";

import { TextInput, FormFilter, SubmitSearchButton, ClearFiltersButton, Select, DateInput } from "components/forms";
import { fetchFundersActivityReport, updateFunderFilters } from "actions";
import { buyingStatusChoices, caseStageChoices } from "../../constants";

const FundersListFilterForm = ({ fetchFundersActivityReport, ...props }) => {
  const buyingStatusRef = useRef();
  const stageRef = useRef();

  let stage_initial = [
    { value: "Opportunity", label: "Opportunity" },
    { value: "Price Discovery", label: "Price Discovery" },
    { value: "In Closing", label: "In Closing" },
  ];

  let [stage, setStage] = React.useState("Opportunity,Price Discovery,In Closing");

  const handleStageChange = selectedOptions => {
    if (selectedOptions) {
      const commaSeparatedStatusValues = selectedOptions.map(o => o.value).join(",");
      setStage(commaSeparatedStatusValues);
    } else {
      setStage(undefined);
    }
  };

  return (
    <Formik
      initialValues={{}}
      enableReinitialize
      onSubmit={async (values, { setSubmitting }) => {
        // Handle defaultValue dict
        if (values.buying_status && values.buying_status.value === "Active") {
          delete values.buying_status;
        }
        let stageParam = "";
        if (typeof stage === "string") {
          stageParam = stage;
        }
        await fetchFundersActivityReport({ ...values, case_stage: stageParam });
        setSubmitting(false);
      }}
    >
      {({ handleSubmit, resetForm }) => {
        const resetCallback = () => {
          props.updateFunderFilters({});
          resetForm();
          fetchFundersActivityReport();
          buyingStatusRef.current.select.clearValue();
          stageRef.current.select.clearValue();
        };

        return (
          <FormFilter title={"Funder Search"} onSubmit={handleSubmit}>
            <Form.Row>
              <TextInput label="Funder Name" name="name" md={6} />
              <Select
                label="Funder Status"
                name="buying_status"
                placeholder={"Select Buying Status"}
                options={buyingStatusChoices}
                defaultValue={{ value: "Active", label: "Active" }}
                submitRef={buyingStatusRef}
                menuPortalTarget={document.body}
                md={6}
              />
            </Form.Row>
            <Form.Row>
              <DateInput label="Date Range (Start)" name="date_range_start" md={4} />
              <DateInput label="Date Range (End)" name="date_range_end" md={4} />
              <Select
                label="Stage"
                name="case_stage"
                placeholder="Select Stage"
                isMulti={true}
                onChange={handleStageChange}
                options={caseStageChoices}
                defaultValue={stage_initial}
                submitRef={stageRef}
                md={4}
              />
            </Form.Row>
            <SubmitSearchButton />
            <ClearFiltersButton onClick={resetCallback} />
          </FormFilter>
        );
      }}
    </Formik>
  );
};

export default connect(null, {
  fetchFundersActivityReport,
  updateFunderFilters,
})(FundersListFilterForm);
