import React from "react";
import { Form, Col, InputGroup } from "react-bootstrap";
import { useField, useFormikContext } from "formik";

import { isInvalid } from "./validation";
import Label from "./Label";
import FieldErrors from "./FieldErrors";
import FieldHelpText from "./FieldHelpText";

const TextInput = ({ label, md = "", helpText, isRequired = false, ...props }) => {
  const [field, meta] = useField(props);
  const { setFieldValue } = useFormikContext();

  if (!props.autoComplete) {
    props.autoComplete = "off";
  }

  return (
    <Form.Group as={Col} sm="12" className={`col-md${md && "-" + md}`}>
      <InputGroup>
        <InputGroup.Prepend>
          <InputGroup.Text>{label}</InputGroup.Text>
        </InputGroup.Prepend>
        {props.prepend && (
          <InputGroup.Prepend>
            <InputGroup.Text>{props.prepend}</InputGroup.Text>
          </InputGroup.Prepend>
        )}
        <Form.Control
          {...field}
          {...props}
          onChange={e => {
            let value = e.target.value.replace(/\D/g, "");
            setFieldValue(field.name, value);
          }}
          isInvalid={isInvalid(meta)}
        />
        {props.append && (
          <InputGroup.Append>
            <InputGroup.Text>{props.append}</InputGroup.Text>
          </InputGroup.Append>
        )}
        <FieldErrors meta={meta} />
      </InputGroup>
      {helpText && <FieldHelpText>{helpText}</FieldHelpText>}
    </Form.Group>
  );
};

export default TextInput;
