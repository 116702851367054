import React, { useRef } from "react";
import { connect } from "react-redux";
import { Formik } from "formik";
import { Form } from "react-bootstrap";

import {
  TextInput,
  FormFilter,
  SubmitSearchButton,
  ClearFiltersButton,
  Select,
  StateSelect,
  DateInput,
} from "components/forms";
import { fetchLeads, fetchLifeSettlementChoices, updateLeadsFilters } from "actions";
import { storedLeadsFiltersSelector, managementChoicesSelector } from "reducers";
import { makeChoices } from "../funders/utils";
import { LEAD_ROLES_CHOICES, MARKETING_ACTIONS } from "./constants";
import { leadStageChoices, leadStatusChoices } from "../../constants";
import { prepareMultiSelectInitial } from "../../utils";

const LeadsFilterForm = ({ fetchLeads, fetchLifeSettlementChoices, currentFilters, ...props }) => {
  React.useEffect(() => {
    fetchLifeSettlementChoices();
  }, []);

  const handleStatusChange = selectedOptions => {
    if (selectedOptions) {
      const commaSeparatedStatusValues = selectedOptions.map(o => o.value).join(",");
      setStatus(commaSeparatedStatusValues);
    } else {
      setStatus(undefined);
    }
  };

  const handleStageChange = selectedOptions => {
    if (selectedOptions) {
      const commaSeparatedValues = selectedOptions.map(o => o.value).join(",");
      setStage(commaSeparatedValues);
    } else {
      setStage(undefined);
    }
  };

  let status_initial = prepareMultiSelectInitial("status", leadStatusChoices, currentFilters);
  let [status, setStatus] = React.useState();

  let stage_initial = prepareMultiSelectInitial("stage", leadStageChoices, currentFilters);
  let [stage, setStage] = React.useState();

  let initialValues = {
    last_name: currentFilters?.last_name || "",
    first_name: currentFilters?.first_name || "",
    phone: currentFilters?.phone || "",
    email: currentFilters?.email || "",
    lead_creation_start_date: currentFilters?.lead_creation_start_date || "",
    lead_creation_end_date: currentFilters?.lead_creation_end_date || "",
    state: currentFilters?.state || "",
    sales_representative_id: currentFilters?.sales_representative_id || "",
    role: currentFilters?.role || "",
    source: currentFilters?.source || "",
    notes: currentFilters?.notes || "",
    stage: currentFilters?.stage || "",
    status: currentFilters?.status || "",
  };

  return (
    <Formik
      initialValues={initialValues}
      enableReinitialize
      onSubmit={async (values, { setSubmitting }) => {
        setSubmitting(true);
        const filters = {
          ...values,
          status: status,
          stage: stage,
        };
        props.updateLeadsFilters(filters);
        await fetchLeads(filters);
        setSubmitting(false);
      }}
    >
      {({ handleSubmit, resetForm }) => {
        const statusRef = useRef();

        const resetCallback = () => {
          props.updateLeadsFilters({});
          statusRef.current.select.clearValue();
          resetForm();
          fetchLeads();
        };

        return (
          <FormFilter title={"Leads Search"} onSubmit={handleSubmit}>
            <Form.Row>
              <TextInput label="Last Name" name="last_name" />
              <TextInput label="First Name" name="first_name" />
              <TextInput label="Phone" name="phone" />
              <TextInput label="Email" name="email" />
            </Form.Row>
            <Form.Row>
              <DateInput label="Lead Creation Start Date" name="lead_creation_start_date" />
              <DateInput label="Lead Creation End Date" name="lead_creation_end_date" />
              <StateSelect label="State" name="state" placeholder="Select State" />
              <Select
                label="Sales Rep"
                name="sales_representative_id"
                placeholder="Select Sales Rep"
                options={props.salesRepChoices.getChoices({ sortByLabel: true })}
              />
            </Form.Row>
            <Form.Row>
              <Select
                label="Lead Role"
                name="role"
                placeholder="Select Lead Role"
                options={LEAD_ROLES_CHOICES}
                md={3}
              />
              <Select
                label="Lead Source"
                name="marketing_action"
                placeholder="Select Lead Source"
                options={MARKETING_ACTIONS}
                md={3}
              />
              <TextInput label="Minimum Score" type="number" name="min_score" md={3} />
              <TextInput label="Maximum Score" type="number" name="max_score" md={3} />
            </Form.Row>
            <Form.Row>
              <Select
                label="Stage"
                name="stage"
                placeholder="Select Stage"
                isMulti={true}
                onChange={handleStageChange}
                options={leadStageChoices}
                defaultValue={stage_initial}
                md={3}
              />
              <Select
                label="Status"
                name="status"
                placeholder="Select Status"
                isMulti={true}
                onChange={handleStatusChange}
                options={leadStatusChoices}
                defaultValue={status_initial}
                md={3}
                submitRef={statusRef}
              />
              <TextInput label="Notes" name="notes" />
            </Form.Row>
            <SubmitSearchButton />
            <ClearFiltersButton onClick={resetCallback} />
          </FormFilter>
        );
      }}
    </Formik>
  );
};

const mapStateToProps = state => {
  const choices = managementChoicesSelector(state);
  const currentFilters = storedLeadsFiltersSelector(state);
  let sourcingAgreementChoices = makeChoices(["None", "Active", "Inactive", "Pending"]);

  return {
    salesRepChoices: choices.case_sales_representatives,
    sourcingAgreementChoices,
    currentFilters,
  };
};

export default connect(mapStateToProps, {
  fetchLeads,
  updateLeadsFilters,
  fetchLifeSettlementChoices,
})(LeadsFilterForm);
