import React, { useRef } from "react";
import { connect } from "react-redux";
import { Formik } from "formik";
import { Form } from "react-bootstrap";

import { TextInput, FormFilter, SubmitSearchButton, ClearFiltersButton, Select, DateInput } from "components/forms";
import {
  PARAMETER_CATEGORY_CHOICES,
  PARAMETER_SUB_CATEGORY_CHOICES,
  PARAMETER_SUB_CATEGORY_CHOICES__CONDITIONS,
  PARAMETER_SUB_CATEGORY_CHOICES__PROFILES,
  PARAMETER_SUB_CATEGORY_CHOICES__TREATMENTS,
} from "../constants";
import { fetchManagerAgents, fetchParameters, storeParametersFiltersData } from "actions";
import { userChoicesSelector } from "../../../reducers";

const ParametersFilterForm = ({ fetchParameters, ...props }) => {
  React.useEffect(() => {
    props.fetchManagerAgents();
  }, []);

  const sectionRef = useRef();
  const section_initial = [];
  let [sections, setSections] = React.useState();
  const handleSectionChange = selectedOptions => {
    if (selectedOptions) {
      const commaSeparatedValues = selectedOptions.map(o => o.value).join(",");
      setSections(commaSeparatedValues);
    } else {
      setSections(undefined);
    }
  };

  let [parameterSubChoices, setParameterChoices] = React.useState(PARAMETER_SUB_CATEGORY_CHOICES);

  return (
    <Formik
      initialValues={{
        operator: "OR",
      }}
      enableReinitialize
      onSubmit={async (values, { setSubmitting }) => {
        const filters = {
          ...values,
          search_sections: sections,
        };
        props.storeParametersFiltersData(filters);
        await fetchParameters(filters);
        setSubmitting(false);
      }}
      onReset={async (values, { setSubmitting }) => {
        props.storeParametersFiltersData({});
        await fetchParameters();
        setSubmitting(false);
        sectionRef.current.select.clearValue();
      }}
    >
      {({ handleSubmit, resetForm }) => {
        return (
          <FormFilter title={"HEALTH PARAMETER SEARCH"} onSubmit={handleSubmit}>
            <Form.Row>
              <Select
                label="Health Param Category"
                name="category"
                placeholder="Select Category"
                options={PARAMETER_CATEGORY_CHOICES}
                onChangeCallback={option => {
                  if (option?.value === "CONDITION") {
                    setParameterChoices(PARAMETER_SUB_CATEGORY_CHOICES__CONDITIONS);
                  } else if (option?.value === "PROFILE") {
                    setParameterChoices(PARAMETER_SUB_CATEGORY_CHOICES__PROFILES);
                  } else if (option?.value === "TREATMENT") {
                    setParameterChoices(PARAMETER_SUB_CATEGORY_CHOICES__TREATMENTS);
                  } else {
                    setParameterChoices(PARAMETER_SUB_CATEGORY_CHOICES);
                  }
                }}
              />
              <Select
                label="Health Param Sub-Category"
                name="sub_category"
                placeholder="Select Sub-Category"
                options={parameterSubChoices}
              />
              <TextInput label="Health Param Name" name="name" />
              <TextInput label="Health Param Code" name="code" />
            </Form.Row>
            <Form.Row>
              <DateInput label="Date Last Updated (Start)" name="last_edited_date_low" />
              <DateInput label="Date Last Updated (End)" name="last_edited_date_high" />
              <Select label="Last Edited By" name="last_editor" options={props.userChoices} md={3} />
              <TextInput label="Related ICD Codes" name="icd_10_codes" />
            </Form.Row>
            <Form.Row>
              <TextInput label="Text Search" name="text_search" md={3} tooltip='Use "word1 word2" for exact search' />
              <Select
                label="Search Sections"
                name="search_sections"
                placeholder="Select Search Sections"
                options={props.searchSectionOptions}
                md={3}
                isMulti={true}
                submitRef={sectionRef}
                onChange={handleSectionChange}
                defaultValue={section_initial}
              />
              <Select
                label="Search Type"
                name="operator"
                options={[
                  { value: "OR", label: "OR" },
                  { value: "AND", label: "AND" },
                ]}
                md={3}
              />
            </Form.Row>
            <SubmitSearchButton />
            <ClearFiltersButton onClick={resetForm} />
          </FormFilter>
        );
      }}
    </Formik>
  );
};

const mapStateToProps = state => {
  const userChoices = userChoicesSelector(state).getChoices({ sortByLabel: true });
  const searchSectionOptions = [
    { value: "aetiology", label: "Aetiology" },
    { value: "assessment_information", label: "Assessment Information" },
    { value: "clinical_research", label: "Clinical Research" },
    { value: "description", label: "Description" },
    { value: "diagnosis", label: "Diagnosis" },
    { value: "epidemiology", label: "Epidemiology" },
    { value: "favorable_considerations", label: "Favorable Considerations" },
    { value: "prognosis", label: "Prognosis" },
    { value: "risk_assessment", label: "Risk Assessment" },
    { value: "search_terms", label: "Search Terms" },
    { value: "severity", label: "Severity" },
    { value: "symptoms", label: "Signs & Symptoms" },
    { value: "treatment", label: "Treatment" },
    { value: "unfavorable_considerations", label: "Unfavorable Considerations" },
    { value: "soft_synonyms_name", label: "Soft Synonyms" },
    { value: "hard_synonyms_name", label: "Hard Synonyms" },
  ];

  return {
    userChoices,
    searchSectionOptions,
  };
};

export default connect(mapStateToProps, {
  fetchParameters,
  storeParametersFiltersData,
  fetchManagerAgents,
})(ParametersFilterForm);
