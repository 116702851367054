import React, { useRef } from "react";
import { connect } from "react-redux";
import { Formik } from "formik";
import { Form } from "react-bootstrap";

import { FormFilter, SubmitSearchButton, Select } from "components/forms";
import { fetchFunderCaseFits } from "actions";
import { activeFunderSelector } from "reducers";
import { caseStatusChoices, activeStageChoices } from "../../constants";

const FunderCasesFitFilterForm = ({ fetchFunderCaseFits, funderInfo, fetchFunderEntities, ...props }) => {
  React.useEffect(() => {
    if (funderInfo && funderInfo.id) {
      fetchFunderCaseFits(funderInfo.id, {
        stage: "Price Discovery",
      });
    }
  }, [funderInfo]);

  React.useEffect(() => {
    if (funderInfo && funderInfo.name) {
      document.title = `LifeRoc | ${funderInfo.name} | Funder Case Fits`;
    }
  }, [funderInfo]);

  let status_initial = [];
  let stage_initial = [{ value: "Price Discovery", label: "Price Discovery" }];
  let funder_status_initial = [];
  let [status, setStatus] = React.useState();
  let [stage, setStage] = React.useState("Price Discovery");
  let [funderStatus, setFunderStatus] = React.useState();
  const handleStatusChange = selectedOptions => {
    if (selectedOptions) {
      const commaSeparatedStatusValues = selectedOptions.map(o => o.value).join(",");
      setStatus(commaSeparatedStatusValues);
    } else {
      setStatus(undefined);
    }
  };

  const handleStageChange = selectedOptions => {
    if (selectedOptions) {
      const commaSeparatedStageValues = selectedOptions.map(o => o.value).join(",");
      setStage(commaSeparatedStageValues);
    } else {
      setStage(undefined);
    }
  };

  const handleFunderStatusChange = selectedOptions => {
    if (selectedOptions) {
      const commaSeparatedFunderStatusValues = selectedOptions.map(o => o.value).join(",");
      setFunderStatus(commaSeparatedFunderStatusValues);
    } else {
      setFunderStatus(undefined);
    }
  };

  const stageRef = useRef();
  const statusRef = useRef();
  const funderStatusRef = useRef();

  return (
    <Formik
      initialValues={{
        name: "",
        status: "",
        stage: "",
        funder_status: "",
      }}
      enableReinitialize
      onSubmit={async (values, { setSubmitting }) => {
        await fetchFunderCaseFits(funderInfo.id, {
          ...values,
          status,
          stage: stage,
          funder_status: funderStatus,
          funder: funderInfo.id,
        });
        setSubmitting(false);
      }}
      onReset={async (values, { setSubmitting }) => {
        await fetchFunderCaseFits(funderInfo.id);
        setSubmitting(false);
        stageRef.current.select.clearValue();
        statusRef.current.select.clearValue();
        funderStatusRef.current.select.clearValue();
      }}
    >
      {({ handleSubmit, resetForm }) => {
        return (
          <FormFilter title={"Funder Case Search"} onSubmit={handleSubmit}>
            <Form.Row>
              <Select
                label="Case Status"
                name="status"
                placeholder="Select Case Status"
                isMulti={true}
                submitRef={statusRef}
                onChange={handleStatusChange}
                options={caseStatusChoices}
                defaultValue={status_initial}
                menuPortalTarget={document.body}
                md={3}
              />
              <Select
                label="Case Stage"
                name="stage"
                placeholder="Select Case Stage"
                isMulti={true}
                submitRef={stageRef}
                onChange={handleStageChange}
                options={activeStageChoices}
                defaultValue={stage_initial}
                menuPortalTarget={document.body}
                md={3}
              />
              <Select
                label="Funder Status"
                name="funder_status"
                placeholder="Select Funder Status"
                options={props.funderStatusChoices}
                md={3}
                isMulti={true}
                submitRef={funderStatusRef}
                onChange={handleFunderStatusChange}
                defaultValue={funder_status_initial}
              />
            </Form.Row>
            <SubmitSearchButton />
          </FormFilter>
        );
      }}
    </Formik>
  );
};

const mapStateToProps = state => {
  let funderStatusChoices = [
    { value: "B", label: "Bidding" },
    { value: "P", label: "Pending" },
    { value: "D", label: "Declined" },
    { value: "LB", label: "Losing Bid" },
    { value: "LC", label: "Lost Case" },
    { value: "WP", label: "Winning Pending" },
    { value: "WC", label: "Winning in Closing" },
    { value: "W", label: "Won" },
    { value: "NS", label: "Not Sent" },
  ];

  return {
    funderInfo: activeFunderSelector(state),
    funderStatusChoices,
  };
};

export default connect(mapStateToProps, {
  activeFunderSelector,
  fetchFunderCaseFits,
})(FunderCasesFitFilterForm);
