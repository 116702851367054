import React from "react";
import { connect } from "react-redux";

import { selectMortalityAssessment, clearSelectedMortalityAssessment } from "actions";
import { Outlet } from "react-router-dom";
import MortalityAssessmentTopHeader from "./MortalityAssessmentTopHeader";
import { compose } from "redux";
import withRouter from "../../withRouter";

/* Data component: fetch relevant data for the current parameter */
const MortalityAssessmentSetup = ({ mortalityAssessmentId, ...props }) => {
  const fetchData = () => {
    props.selectMortalityAssessment(mortalityAssessmentId);
  };

  React.useEffect(() => {
    props.clearSelectedMortalityAssessment();
    fetchData();
  }, []);

  return (
    <div>
      <MortalityAssessmentTopHeader />
      <Outlet />
    </div>
  );
};

const mapStateToProps = (state, ownProps) => {
  const mortalityAssessmentId = ownProps.params.id;

  return {
    mortalityAssessmentId,
  };
};

export default compose(
  withRouter,
  connect(mapStateToProps, {
    selectMortalityAssessment,
    clearSelectedMortalityAssessment,
  }),
)(MortalityAssessmentSetup);
